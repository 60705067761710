import { Dropdown as AntDropdown } from 'antd';
import styled from 'styled-components';
import { Flex } from 'UI';

export const SContainer = styled(Flex)<{
  error: boolean;
}>`
  height: 60px;
  border-radius: 100px 100px 100px 100px;
  background-color: ${({ theme }) => theme.color.white};
  user-select: none;
`;

export const SearchButton = styled.div<{
  disabled: boolean;
}>`
  display: flex;
  cursor: pointer;
  flex: 0 1 175px;
  min-height: 60px;
  background-color: ${({ theme, disabled }) => (disabled ? theme.color.disabled : theme.color.brand)};
  border-radius: 0 100px 100px 0;
  text-align: center;
  padding: 15px 0 15px 0;
`;

export const DropdownMenu = styled.div<{
  type: 'brand' | 'season';
  translateX: number;
  translateY: number;
}>`
  width: ${({ type }) => type === 'season' && '182px'};
  ${({ type }) => type === 'brand' && 'height: 400px'};
  box-shadow: 0 4px 10px 0 ${({ theme }) => theme.color.shadow_2};
  overflow-y: scroll;
  background: white;
  margin-top: ${({ translateY }) => `${translateY}px`};
  padding: 15px;
  display: flex;
  flex-direction: column;
  transform: translateX(${({ translateX }) => `${translateX}px`});
`;

export const SAntDropdown = styled(AntDropdown)<{ bordered: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  height: 40px;
  border: 1px solid ${({ theme, bordered }) => (bordered ? theme.color.grey85 : 'none')};
  border-radius: 3px;
`;

export const BrandMenuItem = styled.div`
  display: flex;
  height: 35px;
  width: 215px;
  align-items: center;
  cursor: pointer;

  img {
    object-fit: contain;
  }
`;
