/* eslint-disable max-len */
import React from 'react';
import { useParams } from 'react-router-dom';
import { Product } from 'pages/CatalogPage/DH/Product/Product';
import { useResetScroll } from 'utils/hooks/useResetScroll';

export const ProductSection = () => {
  const { referenceNumber } = useParams<{ referenceNumber: string }>();

  useResetScroll([referenceNumber]);

  return <Product key={referenceNumber} referenceNumber={referenceNumber} />;
};
