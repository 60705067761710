import { ReferencePriceType } from 'domains/references';
import { ClientView, GarageView, SparePartsViewType } from 'domains/user';

export const LOADING = 'loading';
export const ERROR = 'error';
export const FOUND = 'found';
export const NOT_FOUND = 'not_found';

/**
 * @deprecated This data structure should not be used. Use SearchData<T> instead
 */
export type NO_DATA = undefined | typeof LOADING | typeof NOT_FOUND | typeof ERROR;
export type SEARCH_STATUS = typeof LOADING | typeof NOT_FOUND | typeof FOUND | typeof ERROR | undefined;
export type CURSOR_PAGING_STATUS = typeof LOADING | typeof FOUND;

export interface SearchData<T> {
  searchStatus: SEARCH_STATUS;
  data?: T;
}

export function hasData<T>(value: T | NO_DATA): value is T {
  return !isLoading(value) && !isError(value) && value !== NOT_FOUND && value !== undefined;
}

export function getData<T>(value: T | NO_DATA): T | undefined {
  return hasData(value) ? value : undefined;
}

export function getSearchData<T>(value: SearchData<T> | undefined): T | undefined {
  return hasData(value?.searchStatus) && value?.data ? value.data : undefined;
}

export function isLoading<T>(value: T | NO_DATA): value is typeof LOADING {
  return value === LOADING;
}

export function isNotFound<T>(value: T | NO_DATA): value is typeof NOT_FOUND {
  return value === NOT_FOUND;
}

export function isError<T>(value: T | NO_DATA): value is typeof ERROR {
  return value === ERROR;
}

export function isLoaded<T>(value: T | NO_DATA): value is T {
  return value !== undefined && value !== LOADING;
}

export function hasPrice(
  sparePartsView: SparePartsViewType,
  price: ReferencePriceType | undefined,
): price is ReferencePriceType {
  return (
    (sparePartsView === ClientView && price?.clientView?.recommendedPriceVatIncluded !== undefined) ||
    (sparePartsView === GarageView && price?.garageView?.vatExcludedPrice !== undefined)
  );
}

export function getCondArrayItem<T>(data: T | false | undefined): T[] {
  return data ? [data] : [];
}
