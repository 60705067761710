/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CatalogSource } from '@1po/1po-bff-fe-spec/generated/common/vehicle/CatalogSource';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { Alert } from 'antd';
import { RefSelectProps } from 'antd/lib/select';
import { v4 as uuid } from 'uuid';
import { ROUTER_CATALOG_DH, ROUTER_CATALOG_IAM, ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import { fetchVehicleByBMM } from 'domains/catalog/Catalog.requests';
import {
  fetchSearchBMMVehicleBrandsSaga,
  getIAMVehicleBrands,
  getLastSearchedVehicleKey,
  getLastVehicleDetail,
  getVehicleBrands,
  searchIAMBMMVehicleSaga,
} from 'domains/catalog/Catalog.store';
import { IAMVehicleBrandLocal, VehicleBrandLocal } from 'domains/catalog/Catalog.types';
import {
  Box,
  CenteredSpin,
  CenterFlex,
  Flex,
  FloatingCard,
  FloatingContent,
  Input,
  MarginBox,
  Pipeline,
  Select,
  SelectOption,
  Spin,
  WithTooltip,
  YellowButton,
} from 'UI';
import { getData, isLoading, NO_DATA, rollDownEffect } from 'utils';
import { useFocus } from 'utils/hooks/useFocus';
import { SearchVariantOption, SearchVariantType, SelectSearchVariant } from './SearchBar';
import { SearchByModelDH } from './SearchByModelDH';
import { SearchByModelIAM } from './SearchByModelIAM';

export type SearchByModelStateValue = {
  brand?: string;
  modelFamily?: string;
  modelType?: string;
  engine?: string;
  gearbox?: string;
  country?: string;
  model?: string;
  version?: string;
};

export type SearchByModelState = SearchByModelStateValue & {
  stateId: string;
};

export type SearchByModelOptions = {
  brands?: Array<VehicleBrandLocal | IAMVehicleBrandLocal> | NO_DATA;
};

export interface FormControl {
  formState: SearchByModelState;
  setFormState: (x: SearchByModelState) => void;
  // formStateIndex: number;
  formOptions: SearchByModelOptions;
}

export const SearchByModelAcceptButton = ({
  catalogSource,
  formControl,
  pendingQuery,
  setPendingQuery,
  requestId,
  isFormCompleted,
}: {
  catalogSource: CatalogSource;
  formControl: FormControl;
  pendingQuery: string | undefined;
  setPendingQuery: (x: string | undefined) => void;
  requestId: string;
  isFormCompleted: boolean;
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const formState = formControl.formState;

  const performSearch = () => {
    if (formState.brand && formState.engine && formState.modelType && formState.modelFamily && formState.gearbox) {
      setPendingQuery(requestId);
      fetchVehicleByBMM(
        dispatch,
        formState.brand,
        formState.modelFamily,
        formState.modelType,
        formState.engine,
        formState.gearbox,
        '',
        true,
        catalogSource,
      );
      return;
    }
    if (formState.brand && formState.model && formState.version) {
      setPendingQuery(requestId);
      dispatch(
        searchIAMBMMVehicleSaga({
          brandCode: formState.brand,
          modelCode: formState.model,
          versionCode: formState.version,
          requestId,
        }),
      );
    }
  };

  useHotkeys(
    'enter',
    (event) => {
      event.preventDefault();
      if (isFormCompleted) {
        performSearch();
      }
    },
    [isFormCompleted],
  );

  return (
    <Flex>
      <>
        {pendingQuery === requestId ? (
          <Flex align={'center'} justify={'center'}>
            <MarginBox mt={12}>
              <Spin />
            </MarginBox>
          </Flex>
        ) : (
          <YellowButton onClick={performSearch} disabled={!isFormCompleted} stretch>
            {t('catalog.parts.search.by_model.validate', 'Validate')}
          </YellowButton>
        )}
      </>
    </Flex>
  );
};

export function SelectStep({
  formMapping,
  formIndex,
  formStateIndex,
  placeholder,
  formControl,
  options,
}: {
  formMapping: Array<keyof SearchByModelStateValue>;
  formIndex: number;
  formStateIndex: number;
  placeholder: string;
  formControl: FormControl;
  options: SelectOption[];
}) {
  const ref = useRef<RefSelectProps>(null);
  const propName = formMapping[formIndex];
  const { formState, setFormState } = formControl;
  const value = formState[propName];
  const isActive = formIndex === formStateIndex;

  // open dropdown if the is more than one option, or more than one option in option groups
  useFocus<RefSelectProps>(ref, [
    isActive && options && (options.length !== 1 || ('options' in options[0] && options[0].options.length > 1)),
  ]);

  // this useEffect fills input automatically if there is only one item in options
  useEffect(() => {
    if (
      isActive &&
      options &&
      Array.isArray(options) &&
      options.length === 1 &&
      ('value' in options[0] || ('options' in options[0] && options[0].options.length === 1))
    ) {
      setFormState({
        ...formState,
        [propName]: 'value' in options[0] ? options[0].value : options[0].options[0].value,
      });
    }
  }, [Array.isArray(options) ? options.reduce((acc, next) => acc + '-' + next, '') : options, isActive, ref]);

  const handleClear = () =>
    formMapping.reduce((acc, next, index) => (formIndex <= index ? { ...acc, [next]: undefined } : acc), formState);

  return (
    <Box width={270}>
      <Flex>
        <Select
          notFoundContent={<Alert message={'No matches found'} type={'error'} showIcon />}
          onChange={(value) => {
            if (value) {
              setFormState({ ...handleClear(), [propName]: String(value) });
            }
            ref.current !== null && ref.current.blur();
          }}
          value={value}
          options={Array.isArray(options) ? options : []}
          placeholder={placeholder}
          disabled={formIndex > formStateIndex}
          bordered
          search
          alignLeft
          size={'middle'}
          allowClear
          onClear={() => {
            setFormState(handleClear());
          }}
          passRef={ref}
          autoOpen
        />
      </Flex>
    </Box>
  );
}

export function SearchByModel({
  searchVariant,
  setSearchVariant,
  searchVariantOptions,
  setIsChangeVehiclePopupVisible,
  onFound,
}: {
  searchVariant: SearchVariantType;
  setSearchVariant: (x: SearchVariantType) => void;
  searchVariantOptions: SearchVariantOption[];
  setIsChangeVehiclePopupVisible?: (a: boolean) => void;
  onFound?: (vehicleDetail: VehicleDetail) => void;
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const [formState, setFormStateBase] = useState<SearchByModelState>({ stateId: '' });
  const [brandOptions, setBrandOptions] = useState<Array<VehicleBrandLocal | IAMVehicleBrandLocal> | NO_DATA>(
    undefined,
  );
  const [pendingQuery, setPendingQuery] = useState<string | undefined>(undefined);
  const [formStateTitle, setFormStateTitle] = useState('');

  const setFormState = (x: SearchByModelState) => setFormStateBase({ ...x, stateId: uuid() });
  const formControl = { formState, setFormState, formOptions: { brands: brandOptions } };

  const brandsDH = useSelector(getVehicleBrands);
  const brandsIAM = useSelector(getIAMVehicleBrands);

  const brandsDataDH = getData(brandsDH) ?? [];
  const brandsDataIAM = getData(brandsIAM) ?? [];
  const brands = useMemo(
    () => [...brandsDataDH, ...brandsDataIAM] as Array<VehicleBrandLocal | IAMVehicleBrandLocal> | undefined,
    [brandsDataDH, brandsDataIAM],
  );
  const brandsDep = brands?.reduce((acc, next) => acc + '-' + next, '');
  const lastSearchedVehicleKey = useSelector(getLastSearchedVehicleKey);
  const lastSearchedVehicleDetail = useSelector(getLastVehicleDetail);

  useEffect(() => {
    if (!brandsDH && !isLoading(brandsDH)) {
      dispatch(fetchSearchBMMVehicleBrandsSaga());
    }
  }, [brandsDH]);

  useEffect(() => {
    setBrandOptions(brands);
  }, [brandsDep]);

  useEffect(() => {
    if (pendingQuery && lastSearchedVehicleKey === pendingQuery) {
      setIsChangeVehiclePopupVisible && setIsChangeVehiclePopupVisible(false);
      setTimeout(() => {
        //300ms for animation of closing popup
        setSearchVariant('VINVRN');
      }, 300);

      if (onFound && lastSearchedVehicleDetail !== undefined) {
        onFound(lastSearchedVehicleDetail);
        return;
      }

      // Hack - IAM has only 3 fields
      if (lastSearchedVehicleKey.split('_').length === 3) {
        history.push(`${ROUTER_CATALOG_IAM}/${lastSearchedVehicleKey}${ROUTER_CATALOG_VEHICLE}`);
      } else {
        history.push(`${ROUTER_CATALOG_DH}/${lastSearchedVehicleKey}${ROUTER_CATALOG_VEHICLE}`);
      }
    }
  }, [lastSearchedVehicleKey, pendingQuery]);

  const selectedBrand = Array.isArray(brands) && brands?.find((brand) => brand.code === formState.brand);
  // TODO: !selectedBrand && also set dh flag in store on received data
  const isSelectedBrandRenaultOrDacia = !selectedBrand || (selectedBrand && selectedBrand.source === 'DH');

  useEffect(() => {
    rollDownEffect('search-by-model-card', 'search-by-model-content', !isSelectedBrandRenaultOrDacia);
  }, [isSelectedBrandRenaultOrDacia]);

  return (
    <FloatingCard id={'search-by-model-card'} large={!isSelectedBrandRenaultOrDacia}>
      <FloatingContent id={'search-by-model-content'}>
        <Flex>
          <Box width={1} />
          <SelectSearchVariant
            searchVariant={searchVariant}
            setSearchVariant={setSearchVariant}
            searchVariantOptions={searchVariantOptions}
          />
          <Pipeline size={50} offsetRatio={8} />
          <Flex size={9}>
            <WithTooltip title={formStateTitle}>
              <div style={{ width: '100%' }}>
                <Input
                  onChange={() => undefined}
                  value={formStateTitle}
                  placeholder={t('catalog.parts.search.by_model.hint', 'Information filled progressively...')}
                  readOnly
                  size={'large'}
                  ellipsis
                />
              </div>
            </WithTooltip>
          </Flex>
        </Flex>
        <Pipeline size={'100%'} horizontal />
        <Box height={14} />
        <CenterFlex>
          {isSelectedBrandRenaultOrDacia ? (
            <DataContainer
              data={brandsDH}
              Loading={() => (
                <MarginBox mt={60}>
                  <CenteredSpin />
                </MarginBox>
              )}
              Error={() => (
                <MarginBox mt={45}>
                  <ErrorWithLabel />
                </MarginBox>
              )}
            >
              <SearchByModelDH
                brands={brands}
                formControl={formControl}
                pendingQuery={pendingQuery}
                setPendingQuery={setPendingQuery}
                formStateTitle={formStateTitle}
                setFormStateTitle={setFormStateTitle}
              />
            </DataContainer>
          ) : (
            <DataContainer
              data={brandsIAM}
              Error={() => (
                <MarginBox mt={45}>
                  <ErrorWithLabel />
                </MarginBox>
              )}
            >
              <SearchByModelIAM
                brands={brands}
                formControl={formControl}
                pendingQuery={pendingQuery}
                setPendingQuery={setPendingQuery}
                formStateTitle={formStateTitle}
                setFormStateTitle={setFormStateTitle}
              />
            </DataContainer>
          )}
        </CenterFlex>
      </FloatingContent>
    </FloatingCard>
  );
}
