import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import styled from 'styled-components';
import { ROUTER_CATALOG_DETAILS } from 'app/AppRouter';
import {
  ApplicabilityIcon,
  ArrowClockIcon,
  BoltIcon,
  CalendarAltIcon,
  CarIcon,
  EngineIcon,
  ExchangeAltIcon,
  GasIcon,
  GearboxIcon,
} from 'assets/icons';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton';
import { SCenter } from 'components/Page/PageSideBar/PageSideBar.styled';
import Popover from 'components/Popover';
import { getCatalogSourceUrl } from 'domains/catalog/Catalog.utils';
import { CarDetailHeaderImage } from 'pages/CatalogPage/common/index';
import { ActionBox, ClickBox, SSearchShortcutWrapper, SubTitleText } from 'pages/CatalogPage/DH/CatalogPage.styled';
import SearchHistoryContent from 'pages/CatalogPage/DH/PopoverContent';
import { useGetCriteria } from 'pages/CatalogPage/DH/VehicleTechnicalDetails/TechnicalCriteria';
import { SearchBarFullBase } from 'pages/HomePage/search/SearchBar';
import { theme } from 'styles';
import { Box, capitalize, CenterFlex, Flex, Icon, IconType, Link, MarginBox, Text, WithTooltip } from 'UI';
import { textFormatter } from 'utils';
import { useResetScrollSharp } from 'utils/hooks/useResetScroll';
import { Country } from 'utils/i18n/Country';

export const Label = styled.div`
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SFlex = styled(Flex)`
  box-shadow: -6px 0 white, 6px 0 white, 0 7px 5px -2px rgba(0, 0, 0, 0.1);
`;

export const CatalogButton = ({
  id,
  icon,
  title,
  link,
  content,
  contentTitle,
  isPopoverVisible,
  onPopoverVisibleChange,
}: {
  id: string;
  icon: IconType;
  title: string;
  link?: string;
  content: React.ReactElement;
  contentTitle?: string;
  isPopoverVisible?: boolean;
  onPopoverVisibleChange?: (visible: boolean) => void;
}) => {
  const location = useLocation();
  const renderButton = link ? location.pathname !== link : true;
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    isPopoverVisible !== undefined && setIsVisible(isPopoverVisible);
  }, [isPopoverVisible]);

  if (!renderButton) return null;

  const CatalogIconButton = () => (
    <MarginBox mt={6}>
      <ClickBox>
        <Icon IconComponent={icon} color={'black'} size={24} display={'block'} ml={'auto'} mr={'auto'} />
        <SCenter>
          <SubTitleText type={'light_12_black_85'} cursor={'pointer'}>
            {title}
          </SubTitleText>
        </SCenter>
      </ClickBox>
    </MarginBox>
  );

  return (
    <ActionBox>
      {link ? (
        <Link to={link}>
          <CatalogIconButton />
        </Link>
      ) : (
        <Popover
          id={id}
          visible={isVisible}
          onVisibleChange={(visible: boolean) => {
            setIsVisible(visible);
            onPopoverVisibleChange && onPopoverVisibleChange(visible);
          }}
          trigger={'click'}
          title={contentTitle}
          content={content}
          placement={'bottomRight'}
          arrowPointAtCenter={true}
        >
          <CatalogIconButton />
        </Popover>
      )}
    </ActionBox>
  );
};

export const ChangeVehiclePopup = ({
  visible,
  setIsChangeVehiclePopupVisible,
  onFound,
  setIsChangeVehicle,
}: {
  visible?: boolean;
  setIsChangeVehiclePopupVisible: (a: boolean) => void;
  onFound?: (vehicleDetail: VehicleDetail) => void;
  setIsChangeVehicle?: (b: boolean) => void;
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    return () => {
      if (setIsChangeVehicle) {
        setIsChangeVehicle(true);
      }
    };
    // eslint-disable-next-line
  }, []);

  return (
    <SSearchShortcutWrapper>
      <MarginBox ml={15}>
        <Text type={'h5_bold'}>{t('catalog.parts.action.search_vehicle', 'Please identify your vehicle')}</Text>
      </MarginBox>
      <MarginBox mt={30}>
        <SearchBarFullBase
          visible={visible}
          setIsChangeVehiclePopupVisible={setIsChangeVehiclePopupVisible}
          onFound={onFound}
        />
      </MarginBox>
    </SSearchShortcutWrapper>
  );
};

const CatalogButtons = ({
  query,
  setIsChangeVehicle,
}: {
  query: string;
  setIsChangeVehicle?: (b: boolean) => void;
}) => {
  const { t } = useTranslation();
  const [isChangeVehiclePopupVisible, setIsChangeVehiclePopupVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsChangeVehiclePopupVisible(false);
  }, [query]);

  return (
    <Flex direction={'row'} justify={'flex-end'} size={0}>
      <CatalogButton
        id={'change_vehicle_button_' + query}
        title={t('catalog.parts.action.change_vehicle', 'Change vehicle')}
        icon={ExchangeAltIcon}
        content={
          <ChangeVehiclePopup
            visible={isChangeVehiclePopupVisible}
            setIsChangeVehiclePopupVisible={setIsChangeVehiclePopupVisible}
            setIsChangeVehicle={setIsChangeVehicle}
          />
        }
        contentTitle={t('catalog.parts.action.change_vehicle', 'Change vehicle')}
        isPopoverVisible={isChangeVehiclePopupVisible}
        onPopoverVisibleChange={(visible: boolean) => setIsChangeVehiclePopupVisible(visible)}
      />
      <MarginBox ml={20} />
      <CatalogButton
        id={'show_vehicle_history_button_' + query}
        title={t('catalog.parts.action.search_history', 'Search history')}
        icon={ArrowClockIcon}
        content={<SearchHistoryContent />}
        contentTitle={t('catalog.parts.action.search_history', 'Search history')}
      />
    </Flex>
  );
};

export const VehicleTabTitle = ({
  result,
  isBMM,
  link,
  countryName,
}: {
  result: VehicleDetail;
  isBMM: boolean;
  link?: string;
  countryName: string | undefined;
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const country = Country.findByKey(countryName);
  return (
    <Flex align={'center'}>
      <Text type={'lead_text_white'}>
        {[
          result.vehicleBrand,
          result.model,
          result.iamVehicle &&
            !isBMM &&
            result.iamVehicle.phase &&
            t('catalog.parts.banner.phase', 'Phase {{phase}}', { phase: result.iamVehicle.phase }),
        ]
          .filter(Boolean)
          .join(' ')}
      </Text>
      <MarginBox ml={100} />
      {!isBMM && country && (
        <Icon
          IconComponent={country.icon}
          width={16}
          height={16}
          round
          onClick={() => link && history.push(link)}
          dataCy={'country'}
        />
      )}
      {result.vrn && (
        <Flex align={'center'} gap={20} justify={'center'}>
          <MarginBox ml={10}>
            <Text type={'lead_text_white'} disableGutter>
              {result.vrn}
            </Text>
          </MarginBox>
          <Flex justify={'center'} align={'center'}>
            <CopyToClipboardButton textToCopy={result.vrn} inverted size={22} />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

const VINField = ({ result, isBMM }: { result: VehicleDetail; isBMM: boolean }) => {
  const { t } = useTranslation();
  return (
    <>
      {!isBMM && result.vin && (
        <>
          <Text type={'text_bold'} dataCy={'vin'}>
            {t('catalog.parts.banner.vin', 'VIN: {{vin}}', { vin: result.vin })}
          </Text>
          <MarginBox mr={10} />
          <CopyToClipboardButton textToCopy={result.vin} />
          <MarginBox mr={30} />
        </>
      )}
    </>
  );
};

export const IAMField = ({ result }: { result: VehicleDetail }) => {
  const { t } = useTranslation();
  const toDate =
    result.dataHubVehicle === undefined && result.iamVehicle && result.iamVehicle.toDate
      ? ` - ${result.iamVehicle.toDate}`
      : '';
  return (
    <>
      {result.dataHubVehicle && result.dataHubVehicle.modelType && (
        <>
          <Icon IconComponent={CarIcon} height={16} width={16} display={'flex'} mr={10} mt={2} noPointer />
          <Text type={'text'} dataCy={'model-type'}>
            {result.dataHubVehicle?.modelType && result.dataHubVehicle.modelType}
          </Text>
        </>
      )}
      {result.dataHubVehicle === undefined &&
        result.iamVehicle &&
        (result.iamVehicle.doors || result.iamVehicle.bodyType) && (
          <>
            <Icon IconComponent={CarIcon} height={16} width={16} display={'flex'} mr={10} mt={2} noPointer />
            <Text type={'text'}>
              {result.iamVehicle?.bodyType && capitalize(result.iamVehicle.bodyType.toLowerCase())}
              {result.iamVehicle.doors &&
                ` ${t('catalog.parts.banner.doors', '{{doors}} doors', { doors: result.iamVehicle.doors })}`}
            </Text>
          </>
        )}
      {(result.manufacturingDate ||
        result.iamVehicle?.registrationDate ||
        result.iamVehicle?.toDate ||
        result.iamVehicle?.fromDate) && (
        <>
          <>
            <Icon
              IconComponent={CalendarAltIcon}
              height={16}
              width={16}
              display={'flex'}
              mr={10}
              ml={
                // eslint-disable-next-line max-len
                (result.dataHubVehicle === undefined &&
                  result.iamVehicle &&
                  (result.iamVehicle.doors || result.iamVehicle.bodyType)) ||
                (result.dataHubVehicle && result.dataHubVehicle.modelType)
                  ? 30
                  : 0
              }
              mt={2}
              noPointer
            />
            <Text type={'text'} dataCy={'manufacture-date'}>
              {result.dataHubVehicle &&
                result.manufacturingDate &&
                textFormatter.formatDate(new Date(result.manufacturingDate))}
              {result.dataHubVehicle === undefined &&
                result.iamVehicle &&
                (result.iamVehicle.registrationDate
                  ? result.iamVehicle.registrationDate
                  : result.iamVehicle.fromDate
                  ? `${result.iamVehicle.fromDate}${toDate}`
                  : '')}
            </Text>
          </>
          <MarginBox ml={30} />
        </>
      )}
    </>
  );
};

const DHField = ({
  result,
  isBMM,
  hpPowerDHKW,
  hpPowerDHHP,
}: {
  result: VehicleDetail;
  isBMM: boolean;
  hpPowerDHKW: number | string | undefined;
  hpPowerDHHP: number | false;
}) => {
  const { t } = useTranslation();
  const gearbox = result.dataHubVehicle && `${capitalize(result.dataHubVehicle.gearbox.toLowerCase())}`;

  return (
    <>
      {result.dataHubVehicle && (
        <Flex align={'center'}>
          {result.dataHubVehicle.engine && (
            <>
              <Icon IconComponent={EngineIcon} height={16} width={16} display={'flex'} mr={10} noPointer />
              <Text type={'text'} dataCy={'engine-type'}>{`${result.dataHubVehicle.engine}`}</Text>
            </>
          )}
          <MarginBox ml={30} />
          {!isBMM && result.dataHubVehicle.powerKw && (
            <>
              <Icon IconComponent={BoltIcon} height={16} width={16} display={'flex'} mr={10} noPointer />
              <Text type={'text'} dataCy={'energy-type'}>
                {`${hpPowerDHKW && t('catalog.parts.banner.kw', '{{kw}} kW', { kw: hpPowerDHKW })}
                ${hpPowerDHKW && hpPowerDHHP ? '\u00A0' : ''}
                ${hpPowerDHHP && t('catalog.parts.banner.hp_alt', '/ {{hp}} HP', { hp: hpPowerDHHP })}`}
              </Text>
              <MarginBox ml={30} />
            </>
          )}
          {result.dataHubVehicle.energyType && (
            <>
              <Icon IconComponent={GasIcon} height={16} width={16} display={'flex'} mr={10} noPointer />
              <Text type={'text'} dataCy={'fuel-type'}>
                {`${capitalize(result.dataHubVehicle.energyType.toLowerCase())}`}
              </Text>
              <MarginBox ml={30} />
            </>
          )}
          {result.dataHubVehicle && result.dataHubVehicle.gearbox && (
            <>
              <Icon IconComponent={GearboxIcon} height={16} width={16} display={'flex'} mr={10} noPointer />
              <WithTooltip title={gearbox ?? ''}>
                <Label>
                  <Text type={'text'} dataCy={'gearbox-type'} ellipsis noWrap>
                    {gearbox}
                  </Text>
                </Label>
              </WithTooltip>
              <MarginBox ml={30} />
            </>
          )}
        </Flex>
      )}
    </>
  );
};

const GearboxEnergyField = ({ result }: { result: VehicleDetail }) => {
  const gearboxCode = result.iamVehicle && result.iamVehicle.gearboxCode && `[${result.iamVehicle.gearboxCode}]`;

  return (
    <>
      {result.iamVehicle && result.iamVehicle.energy && (
        <>
          <Icon IconComponent={GasIcon} height={16} width={16} display={'flex'} mr={10} noPointer />
          <Text type={'text'}>{`${capitalize(result.iamVehicle.energy.toLowerCase())}`}</Text>
          <MarginBox ml={30} />
        </>
      )}
      {result.iamVehicle &&
        (result.iamVehicle.gearbox || result.iamVehicle.gearboxType || result.iamVehicle.gearboxCode) && (
          <>
            <Icon IconComponent={GearboxIcon} height={16} width={16} display={'flex'} mr={10} noPointer />
            <Text type={'text'}>{`${
              result.iamVehicle.gearboxType ? capitalize(result.iamVehicle.gearboxType.toLowerCase()) : ''
            }${result.iamVehicle.gearboxType && result.iamVehicle.gearbox ? ' ' : ''}${
              result.iamVehicle.gearbox ? capitalize(result.iamVehicle.gearbox.toLowerCase()) : ''
            }${
              (result.iamVehicle.gearboxType || result.iamVehicle.gearbox) && result.iamVehicle.gearboxCode ? ' ' : ''
            }${result.iamVehicle.gearboxCode ? gearboxCode : ''}`}</Text>
            <MarginBox ml={30} />
          </>
        )}
    </>
  );
};

const OtherField = ({ result, hpPowerIAM }: { result: VehicleDetail; hpPowerIAM: number | string | undefined }) => {
  const { t } = useTranslation();
  return (
    <>
      {result.iamVehicle && result.dataHubVehicle === undefined && (
        <Flex align={'center'}>
          {(result.iamVehicle.version || result.iamVehicle.engineCodes) && (
            <>
              <Icon IconComponent={EngineIcon} height={16} width={16} display={'flex'} mr={10} noPointer />
              <Text type={'text'}>
                {`${result.iamVehicle.version ? result.iamVehicle.version : ''}
                ${result.iamVehicle.engineCodes ? ` [${result.iamVehicle.engineCodes}]` : ''}`}
              </Text>
            </>
          )}
          <MarginBox ml={30} />
          {result.iamVehicle.kwPower && (
            <>
              <Icon IconComponent={BoltIcon} height={16} width={16} display={'flex'} mr={10} noPointer />
              <Text type={'text'} dataCy={'energy-type'}>
                {`
                  ${
                    result.iamVehicle.kwPower
                      ? t('catalog.parts.banner.kw', '{{kw}} kW', { kw: result.iamVehicle.kwPower })
                      : ''
                  }
                  ${result.iamVehicle.kwPower && hpPowerIAM ? ' / ' : ''}
                  ${hpPowerIAM ? t('catalog.parts.banner.hp', '{{hp}} HP', { hp: hpPowerIAM }) : ''}
                `}
              </Text>
              <MarginBox ml={30} />
            </>
          )}
          <GearboxEnergyField result={result} />
        </Flex>
      )}
    </>
  );
};

const VehicleStatus = ({ result }: { result: VehicleDetail }) => {
  const { t } = useTranslation();
  const criteria = useGetCriteria();
  const simplifiedApplicability = criteria.simplifiedApplicability;
  const editedTechnicalCriteria = criteria.items
    .map((itemElement) => itemElement.data)
    .flat()
    .some((x) => !x?.enabled);

  return (
    <>
      {(simplifiedApplicability || editedTechnicalCriteria) && (
        <Flex size={0} align={'flex-end'}>
          <MarginBox ml={15}>
            <WithTooltip
              title={
                simplifiedApplicability
                  ? t('catalog.parts.simplified_applicability', 'Simplified applicability')
                  : t('catalog.parts.modified_criteria', 'Modified criteria')
              }
            >
              <div>
                <Link to={`${getCatalogSourceUrl(result.catalogSource)}/${result.vehicleKey}${ROUTER_CATALOG_DETAILS}`}>
                  <Icon IconComponent={ApplicabilityIcon} width={32} height={32} />
                </Link>
              </div>
            </WithTooltip>
          </MarginBox>
        </Flex>
      )}
    </>
  );
};

export const VehicleTabContentBase = ({
  result,
  vehicleImage,
  isBMM,
  isPopover,
  isSticky = false,
}: {
  result: VehicleDetail;
  vehicleImage: string | undefined;
  isBMM: boolean;
  isPopover?: boolean;
  isSticky?: boolean;
}) => {
  const { t } = useTranslation();
  const [isShowFull, setIsShowFull] = useState(false);
  const hpPowerIAM = result.iamVehicle?.hpPower ?? Math.floor(1.341 * Number(result.iamVehicle?.kwPower));
  const hpPowerDHKWsub =
    result.dataHubVehicle && result.dataHubVehicle.powerKw && result.dataHubVehicle.powerKw.substring(0, 3);
  const hpPowerDHKW = isNaN(Number(hpPowerDHKWsub)) ? hpPowerDHKWsub : Number(hpPowerDHKWsub);
  const hpPowerDHHP = typeof hpPowerDHKW === 'number' && Math.floor(1.341 * hpPowerDHKW);
  const isIAM = result.catalogSource === 'IAM';

  useEffect(() => {
    setIsShowFull(!isSticky);
  }, [isSticky]);

  const renderShowLess = () => {
    return (
      <Text type={'link'} hoverUnderLine cursor={'pointer'} onClick={() => setIsShowFull(false)}>
        {t('common.action.show_less', 'Show less')}
      </Text>
    );
  };

  return (
    <Box>
      <Flex>
        <Box width={90}>
          <CenterFlex>
            <CarDetailHeaderImage
              imageUrl={vehicleImage}
              catalogSource={result.catalogSource}
              isSmall={isSticky && !isShowFull}
            />
          </CenterFlex>
        </Box>
        <Flex direction={'column'} justify={'center'} minHeight={isSticky && !isShowFull ? 50 : 70}>
          {isPopover && (
            <>
              <Text type={'highlight'}>
                {[
                  result.vehicleBrand,
                  result.model,
                  result.iamVehicle &&
                    !isBMM &&
                    result.iamVehicle.phase &&
                    t('catalog.parts.banner.phase', 'Phase {{phase}}', { phase: result.iamVehicle.phase }),
                ]
                  .filter(Boolean)
                  .join(' ')}
              </Text>
              <MarginBox mt={5} />
            </>
          )}
          <Flex align={'center'}>
            <VINField result={result} isBMM={isBMM} />
            <Flex>
              <IAMField result={result} />
              {isSticky && !isShowFull && (
                <Text type={'link'} hoverUnderLine cursor={'pointer'} onClick={() => setIsShowFull(true)}>
                  {t('common.action.show_more', 'Show more')}
                </Text>
              )}
            </Flex>
          </Flex>
          {isShowFull && !isIAM && (
            <Flex align={'center'}>
              <DHField result={result} isBMM={isBMM} hpPowerDHKW={hpPowerDHKW} hpPowerDHHP={hpPowerDHHP} />
              {isSticky && renderShowLess()}
            </Flex>
          )}
          {isShowFull && isIAM && (
            <Flex align={'center'}>
              <OtherField result={result} hpPowerIAM={hpPowerIAM} />
              {isSticky && renderShowLess()}
            </Flex>
          )}
        </Flex>
        <VehicleStatus result={result} />
      </Flex>
    </Box>
  );
};

export const VehicleTabContent = ({
  result,
  vehicleImage,
  isBMM,
  query,
  setIsChangeVehicle,
  isSticky,
}: {
  result: VehicleDetail;
  vehicleImage: string | undefined;
  isBMM: boolean;
  query: string;
  setIsChangeVehicle?: (b: boolean) => void;
  isSticky?: boolean;
}) => {
  useResetScrollSharp();

  return (
    <SFlex background={theme.color.white}>
      <VehicleTabContentBase result={result} vehicleImage={vehicleImage} isBMM={isBMM} isSticky={isSticky} />
      <Flex />
      <Box>
        <CatalogButtons query={query} setIsChangeVehicle={setIsChangeVehicle} />
      </Box>
    </SFlex>
  );
};
