import React from 'react';
import { SpinSize } from 'antd-v5/lib/spin';
import { Spin } from 'UI';
import { LoaderWrapper } from './Loader.styled';

export interface LoaderProps {
  message?: string;
  color?: string;
  size?: SpinSize;
  height?: string;
}

const Loader = ({ size = 'large', height = '100vh' }: LoaderProps) => (
  <LoaderWrapper height={height}>
    <Spin size={size} />
  </LoaderWrapper>
);

export default Loader;
