import React, { PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TireSeasonSelection } from 'components/TireSeasonSelection';
import { toSearchParams } from 'domains/tires/Tire.mapper';
import {
  getTireSearchDimensions,
  getTireSearchQuery,
  setSearchParams,
  setTireSearchQuery,
  tireProductsSearchRequestSaga,
} from 'domains/tires/Tire.store';
import { TireBrandSelection } from 'pages/TiresPage/TireSearch/TireBrandSelection';
import { TireDimensionsMenuItem } from 'pages/TiresPage/TireSearch/TireDimensionsSearchBar/TireDimensionsMenuItem';
import {
  BlackHalfCard,
  SearchDimensionsContainer,
} from 'pages/TiresPage/TireSearch/TireDimensionsSearchBar/TireDimensionsSearchBar.styled';
import { TireSearchProps } from 'pages/TiresPage/TireSearch/TireSearch';
import { theme } from 'styles';
import { Box, Flex, MarginBox, Text, YellowButton } from 'UI';

interface TireDimensionsItemWrapper extends PropsWithChildren {
  label?: string;
}

const TireDimensionsItemWrapper = ({ label, children }: TireDimensionsItemWrapper) => (
  <Flex direction={'column'} minWidth={220} maxWidth={220}>
    <MarginBox mx={5} mt={15}>
      {label && (
        <>
          <Text type={'light_dimmer'}>{label}</Text>
          <MarginBox mt={5} />
        </>
      )}
      {children}
    </MarginBox>
  </Flex>
);

interface TireInputDimensions {
  width?: string;
  series?: string;
  diameter?: string;
  chargeIndicator?: string;
  speedIndicator?: string;
}

function getTireParamsText(
  width: string,
  series: string,
  diameter: string,
  speedIndicator?: string,
  chargeIndicator?: string,
  delimiter = '/',
): string {
  const baseParams = `${width}${delimiter}${series}${delimiter}${diameter}`;
  return (
    baseParams +
    `${chargeIndicator && chargeIndicator.length > 0 ? delimiter + chargeIndicator : ''}` +
    `${speedIndicator && speedIndicator.length > 0 ? delimiter + speedIndicator : ''}`
  );
}

function getDimensionsSearchQuery(
  width: string,
  series: string,
  diameter: string,
  speedIndicator?: string,
  chargeIndicator?: string,
  delimiter = ' ',
): string {
  const baseParams = `${width}${delimiter}${series}${delimiter}${diameter}`;
  return (
    baseParams +
    `${delimiter}${chargeIndicator && chargeIndicator.length > 0 ? chargeIndicator : ''}` +
    `${delimiter}${speedIndicator && speedIndicator.length > 0 ? speedIndicator : ''}`
  );
}

type TireDimensionsSearchBarProps = Omit<TireSearchProps, 'setQuery'>;

export const TireDimensionsSearchBar = ({ brands, setBrands, season, setSeason }: TireDimensionsSearchBarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tireSearchDimensions = useSelector(getTireSearchDimensions);
  const [menuOpen, setMenuOpen] = useState(false);
  const query = useSelector(getTireSearchQuery).toUpperCase();
  const brandIds = brands.map((brand) => brand.brandId);
  const { width, series, diameter, speedIndicator, chargeIndicator } = toSearchParams(query, season, brandIds);
  const [searchDimensions, setSearchDimensions] = useState<TireInputDimensions>({
    width,
    series,
    diameter,
    speedIndicator,
    chargeIndicator,
  });

  const handleSearch = () => {
    const selectedBrands = brands.filter((b) => b.selected);
    if (selectedBrands && searchDimensions.width && searchDimensions.series && searchDimensions.diameter) {
      const queryValue = getDimensionsSearchQuery(
        searchDimensions.width,
        searchDimensions.series,
        searchDimensions.diameter,
        searchDimensions.speedIndicator,
        searchDimensions.chargeIndicator,
      );
      dispatch(
        tireProductsSearchRequestSaga({
          query: queryValue,
          season,
          brands: selectedBrands.map((brand) => brand.brandId),
        }),
      );
      dispatch(
        setSearchParams({
          searchParamsBase64: toSearchParams(
            queryValue,
            season,
            selectedBrands.map((brand) => brand.brandId),
          ).searchParamsBase64,
        }),
      );
      dispatch(setTireSearchQuery({ query: queryValue }));
    }
  };

  const menuItems: {
    id: string;
    label: string;
    initialValue?: string;
    onValueChange: (newValue: string | undefined) => void;
    items: string[];
  }[] = [
    {
      id: 'tire-search-criteria-width',
      initialValue: searchDimensions.width,
      label: t('catalog.tires.search.criteria.width', 'Width'),
      onValueChange: (newWidth) => setSearchDimensions({ ...searchDimensions, width: newWidth }),
      items: tireSearchDimensions?.width ?? [],
    },
    {
      id: 'tire-search-criteria-series',
      initialValue: searchDimensions.series,
      label: t('catalog.tires.search.criteria.ratio', 'Ratio'),
      onValueChange: (newSeries) => setSearchDimensions({ ...searchDimensions, series: newSeries }),
      items: tireSearchDimensions?.series ?? [],
    },
    {
      id: 'tire-search-criteria-diameter',
      initialValue: searchDimensions.diameter,
      label: t('catalog.tires.search.criteria.diameter', 'Diameter'),
      onValueChange: (newDiameter) => setSearchDimensions({ ...searchDimensions, diameter: newDiameter }),
      items: tireSearchDimensions?.diameter ?? [],
    },
    {
      id: 'tire-search-criteria-chargeIndicator',
      initialValue: searchDimensions.chargeIndicator,
      label: t('catalog.tires.search.criteria.load_index', 'Load index'),
      onValueChange: (newCharge) => setSearchDimensions({ ...searchDimensions, chargeIndicator: newCharge }),
      items: tireSearchDimensions?.chargeIndicator ?? [],
    },
    {
      id: 'tire-search-criteria-speedIndicator',
      initialValue: searchDimensions.speedIndicator,
      label: t('catalog.tires.search.criteria.speed_index', 'Speed index'),
      onValueChange: (newSpeed) => setSearchDimensions({ ...searchDimensions, speedIndicator: newSpeed }),
      items: tireSearchDimensions?.speedIndicator ?? [],
    },
  ];

  return (
    <MarginBox mx={10} my={15}>
      <Flex
        direction={'column'}
        align={'flex-start'}
        justify={'center'}
        maxHeight={230}
        minWidth={1150}
        maxWidth={'100%'}
      >
        <Flex minWidth={200}>
          <BlackHalfCard>
            <Text type={'h5_light_white'}>{`${t('catalog.tires.search.tire', 'Tire:')} ${getTireParamsText(
              width,
              series,
              diameter,
              speedIndicator,
              chargeIndicator,
            )}`}</Text>
          </BlackHalfCard>
        </Flex>
        <SearchDimensionsContainer
          align={'flex-end'}
          justify={'space-between'}
          maxWidth={1150}
          wrap={'wrap'}
          background={theme.color.white}
          overflowY={'hidden'}
        >
          {menuItems.map((menuItem) => (
            <TireDimensionsItemWrapper label={menuItem.label} key={menuItem.id}>
              <TireDimensionsMenuItem
                initialValue={menuItem.initialValue}
                onValueChange={menuItem.onValueChange}
                label={menuItem.label}
                items={menuItem.items}
              />
            </TireDimensionsItemWrapper>
          ))}
          <TireDimensionsItemWrapper label={t('catalog.tires.search.criteria.seasonality', 'Seasonality')}>
            <Box width={200}>
              <TireSeasonSelection
                season={season}
                setSeason={(newSeason) => setSeason(newSeason)}
                menuOpen={menuOpen}
                setMenuOpen={(isOpen) => setMenuOpen(isOpen)}
                showValue
                bordered
              />
            </Box>
          </TireDimensionsItemWrapper>
          <TireDimensionsItemWrapper label={t('catalog.tires.search.criteria.brands', 'Brands')}>
            <Box width={200}>
              <TireBrandSelection brands={brands} setBrands={setBrands} bordered />
            </Box>
          </TireDimensionsItemWrapper>
          <TireDimensionsItemWrapper>
            <Box width={200}>
              <YellowButton
                stretch
                onClick={handleSearch}
                disabled={!searchDimensions.width || !searchDimensions.series || !searchDimensions.diameter}
              >
                {t('catalog.tires.search', 'Search')}
              </YellowButton>
            </Box>
          </TireDimensionsItemWrapper>
        </SearchDimensionsContainer>
      </Flex>
    </MarginBox>
  );
};
