import React, { Ref, useEffect, useState } from 'react';
import { Input as AntInput, InputRef } from 'antd';
import { SizeType } from 'antd/lib/config-provider/SizeContext';
import { useInput, UseInputParams } from 'utils/hooks/useInput';

export interface InputProps extends UseInputParams<string, HTMLInputElement> {
  id?: string;
  name?: string;
  value?: string;
  initialValue?: string;
  placeholder?: string;
  onPressEnter?: (value: string) => void;
  maxValue?: number;
  size?: SizeType;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  bordered?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  ellipsis?: boolean;
  maxLength?: number;
  color?: string;
  passRef?: Ref<InputRef>;
  className?: string;
  showCount?: boolean;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  onInput?: React.FormEventHandler<HTMLInputElement>;
  status?: 'warning' | 'error';
  addonBefore?: React.ReactNode;
  addonAfter?: React.ReactNode;
  allowClear?: boolean;
  autoComplete?: string;
  dataCy?: string;
}

export const CleverInput = (props: InputProps) => {
  const [focus, setFocus] = useState(false);
  const value = props.value ?? props.initialValue ?? '';
  const [inputValue, setInputValue] = useState<string>(value);

  useEffect(() => {
    if (!focus) {
      setInputValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <Input
      {...props}
      value={inputValue}
      onChange={(val) => {
        setInputValue(val);
        props.onChange && props.onChange(val);
      }}
      onBlur={(val) => {
        setInputValue(val);
        setFocus(false);
        props.onChange && props.onChange(val);
      }}
      onFocus={() => setFocus(true)}
    />
  );
};

export const Input = ({
  id,
  name,
  value,
  initialValue,
  placeholder,
  onChange,
  onChangeDebounced,
  onBlur,
  onBlurDebounced,
  onPressEnter,
  onInput,
  maxValue,
  size = 'middle',
  prefix,
  suffix,
  bordered = false,
  disabled = false,
  readOnly = false,
  ellipsis,
  maxLength,
  color,
  passRef,
  className,
  showCount,
  onKeyPress,
  status,
  addonBefore,
  onFocus,
  addonAfter,
  allowClear,
  autoComplete,
  dataCy,
}: InputProps) => {
  const inputs = useInput<string, HTMLInputElement>({
    onChange,
    onChangeDebounced,
    onBlur,
    onBlurDebounced,
    onFocus,
    maxValue,
  });

  return (
    <AntInput
      id={id}
      name={name}
      value={value}
      defaultValue={initialValue}
      placeholder={placeholder}
      onChange={(e) => inputs.onChange(e.target.value, e)}
      onBlur={(e) => inputs.onBlur(e.target.value, e)}
      onFocus={(e) => inputs.onFocus(e.target.value, e)}
      onPressEnter={(event) => {
        const target = event.target as HTMLInputElement;
        if (onPressEnter) onPressEnter(target.value);
        else target.blur();
      }}
      size={size}
      bordered={bordered}
      prefix={prefix}
      suffix={suffix}
      disabled={disabled}
      readOnly={readOnly}
      style={{ textOverflow: ellipsis ? 'ellipsis' : undefined, color }}
      color={color}
      maxLength={maxLength}
      ref={passRef}
      spellCheck={false}
      className={className}
      onKeyDown={onKeyPress}
      showCount={showCount}
      status={status}
      addonAfter={addonAfter}
      addonBefore={addonBefore}
      data-cy={dataCy}
      onInput={onInput}
      allowClear={allowClear}
      autoComplete={autoComplete ?? (name ? 'on' : undefined)}
    />
  );
};
