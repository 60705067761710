import React from 'react';
import { OrderReferenceItem } from '@1po/1po-bff-fe-spec/generated/order/response/model/OrderItem';
import { Document, Page, Text as PdfText, View } from '@react-pdf/renderer';
import {
  PictureCalendar,
  PictureCheckCircle,
  PictureClient,
  PictureNote,
  PictureReturn,
  PictureStatus,
} from 'components/Pdf/PdfSvg';
import {
  BorderlessCard,
  Divider,
  ItemNameTitle,
  Layout2Columns,
  Layout3Columns,
  PageNumber,
  RowItem,
  Section,
  Spacer,
  Subtitle,
  TextDark,
  TextMid,
  TextMidBold,
} from 'components/Pdf/PdfUI';
import { components, fonts, styles } from 'components/Pdf/PdfUI.styles';
import { toDatePdf } from 'domains/order/OrderDate';
import { OrderStatusLabelPdf } from 'domains/order/OrderStatus';
import { textFormatter } from 'utils/format';
import { BillingAddressCard } from './BillingAddressCard';
import { ContactCard } from './ContactCard';
import { Header } from './Header';
import { InformationCard } from './InformationCard';
import { ExternalOrderPdfData } from './MyOrdersDetailPdfPage';
import { TitleItem } from './TitleItem';

export const TotalSection = ({ data }: { data: ExternalOrderPdfData }) => {
  return (
    <BorderlessCard
      title={<ItemNameTitle>{data.text.total}</ItemNameTitle>}
      height={'md'}
      width={'545px'}
      alignTitleStart
    >
      <View style={[styles.col_end, { marginLeft: 7, marginRight: 7, marginTop: 10, marginBottom: 10 }]}>
        <View style={[styles.column, { width: '150px' }]}>
          <View style={[styles.col]}>
            <View style={styles.row}>
              <View style={styles.column}>
                <View style={styles.col}>
                  <TextMid>{data.text.totalVatExcl}</TextMid>
                </View>
              </View>
              <View style={[styles.col]}>
                <TextDark>
                  {textFormatter.formatCurrency(Number(data.order.totalPriceTaxExcluded), data.order.currency)}
                </TextDark>
              </View>
            </View>
            <Spacer />
            <View style={styles.row}>
              <View style={styles.column}>
                <View style={styles.col}>
                  <TextMid>{data.text.totalVat}</TextMid>
                </View>
              </View>
              <View style={[styles.col]}>
                <TextDark>{textFormatter.formatCurrency(Number(data.order.totalTax), data.order.currency)}</TextDark>
              </View>
            </View>
            <Spacer />
            <Divider />
            <Spacer />
            <View style={styles.row}>
              <View style={styles.column}>
                <View style={styles.col}>
                  <TextMidBold>{data.text.totalVatIncl}</TextMidBold>
                </View>
              </View>
              <View style={[styles.col]}>
                <Section>
                  {textFormatter.formatCurrency(Number(data.order.totalPriceTaxIncluded), data.order.currency)}
                </Section>
              </View>
            </View>
          </View>
        </View>
      </View>
    </BorderlessCard>
  );
};

export const TitleCard = ({ data }: { data: ExternalOrderPdfData }) => {
  return (
    <BorderlessCard title={data.text.clientInformation} height={150} width={545} alignTitleStart>
      <Layout2Columns
        leftSide={
          <>
            <Spacer />
            <TitleItem icon={<PictureClient />} label={data.text.client} text={data.garageInfo?.name ?? ''} />
            <TitleItem
              icon={<PictureCalendar />}
              label={data.text.orderDate}
              text={toDatePdf(data.order.creationDate)}
            />
            {data.order.mark && <TitleItem icon={<PictureNote />} label={data.text.orderLine} text={data.order.mark} />}
          </>
        }
        rightSide={
          <>
            <Spacer />
            <TitleItem
              icon={<PictureStatus />}
              label={data.text.status}
              text={<OrderStatusLabelPdf orderStatus={data.order.presentableOrderStatus} />}
            />
          </>
        }
      />
    </BorderlessCard>
  );
};

export const ReferencesTable = ({ data }: { data: ExternalOrderPdfData }) => {
  return (
    <View>
      {data.order.orderedReferences.map((r) => (
        <ReferenceCard key={r.referenceNumber} reference={r} data={data} />
      ))}
    </View>
  );
};

const ReferenceCard = ({ reference, data }: { reference: OrderReferenceItem; data: ExternalOrderPdfData }) => {
  const referenceCardHeight = 45;
  const { price } = reference;
  return (
    <View>
      <View
        style={[
          styles.row,
          {
            width: '545px',
            height: `${referenceCardHeight}px`,
            marginLeft: '5px',
          },
        ]}
      >
        <View
          style={[
            styles.col,
            {
              width: '250px',
              margin: '0 5px 0 5px',
              justifyContent: 'space-between',
            },
          ]}
        >
          <TextMidBold>
            {data.text.ref}
            {` ${reference.referenceNumber}`}
          </TextMidBold>
          <TextMid>{reference.name}</TextMid>
          {/*todo: stocks are waiting for Jamale's implementation*/}
          <View>
            <View style={styles.row}>
              <TextMidBold>{data.text.stocks}</TextMidBold>
            </View>
          </View>
          <View style={[styles.row]}>
            <TextMidBold>{data.text.qty}</TextMidBold>
            <TextMid>
              {`${reference.quantity} `}
              {reference.deliveredQuantity !== undefined &&
                reference.deliveredQuantity !== 0 &&
                ` (${reference.deliveredQuantity} `}
            </TextMid>
            {reference.deliveredQuantity && <TextMid>{data.text.shipped})</TextMid>}
            {reference.returnedQuantity !== undefined && reference.returnedQuantity !== 0 && (
              <>
                <View style={{ marginLeft: '3px', paddingRight: '3px' }}>
                  <PictureReturn />
                </View>
                <TextMid>{data.text.returnedArticles(reference.returnedQuantity)}</TextMid>
              </>
            )}
          </View>
        </View>
        <View
          style={[
            styles.col,
            {
              width: '130px',
              margin: '0 5px 0 5px',
            },
          ]}
        >
          <View style={[{ height: '5px' }]} />
          {reference.referenceMark && (
            <TextMid>
              <TextMidBold>{data.text.referenceMark}</TextMidBold>
              {reference.referenceMark}
            </TextMid>
          )}
        </View>
        <View style={styles.col}>
          <View style={[styles.row_reverse, { width: '100px' }]}>
            <ItemNameTitle>
              {data.text.vatExcl(textFormatter.formatCurrency(Number(price.unitPriceVatExcluded), price.currency))}
            </ItemNameTitle>
          </View>
          <View
            style={[
              styles.col_reverse,
              {
                alignItems: 'flex-end',
                height: '100%',
              },
            ]}
          >
            {reference.isUrgent && (
              <RowItem>
                <View style={[styles.row]}>
                  <PictureCheckCircle />
                  <View style={{ marginLeft: 2 }} />
                  <PdfText style={[fonts.blue]}>{data.text.urgent}</PdfText>
                </View>
              </RowItem>
            )}
          </View>
        </View>
      </View>
      <View style={[{ height: '10px', width: '545px', marginBottom: '10px' }, components.dividerBottom]} />
    </View>
  );
};

export const MyExternalOrdersDetailPdfPage = ({ data }: { data: ExternalOrderPdfData }) => (
  <Document>
    <Page size={'A4'} style={styles.page} wrap={false}>
      <PageNumber />
      <View fixed={true}>
        <Header data={data} />
        <Spacer />
      </View>

      <Layout2Columns
        leftSide={
          <>
            <TitleCard data={data} />
            <Subtitle>{data.text.references}</Subtitle>
            <Spacer height={20} />
          </>
        }
        rightSide={<></>}
      />
      <ReferencesTable data={data} />

      <TotalSection data={data} />

      <Layout3Columns
        left={
          <InformationCard
            text={data.text}
            orderedBy={data.garageInfo.name}
            clientNumber={data.seller.id}
            distributor={data.seller.name}
            billingAccount={data.seller.primaryClientCode}
          />
        }
        middle={<ContactCard text={data.text} garageInfo={data.garageInfo} />}
        right={<BillingAddressCard text={data.text} garageInfo={data.garageInfo} />}
      />
    </Page>
  </Document>
);
