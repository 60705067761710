/* eslint-disable max-len */
import { LinkedReference } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/model/Reference';
import { Reference } from '@1po/1po-bff-fe-spec/generated/estimate/request/model/Reference';
import {
  ClientContact,
  FreeBundleSection,
  LaborTimeSection,
  OtherItemSection,
  ReferenceSection,
  TireSection,
  TotalPrice,
  Vehicle,
  WasteRecyclingSection,
} from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimate';
import { HistoryEstimate } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetEstimateHistory';
import { Settings } from '@1po/1po-bff-fe-spec/generated/estimate/response/GetSettings';
import { APP_NAMESPACE } from 'app/App.constants';
import { NO_DATA, SEARCH_STATUS } from 'utils';

export const ESTIMATE_NAMESPACE = `${APP_NAMESPACE}/ESTIMATE`;

export const GET_ESTIMATE_BY_ID = `${ESTIMATE_NAMESPACE}/GET_BY_ID`;
export const GET_LATEST_ESTIMATE = `${ESTIMATE_NAMESPACE}/GET_LATEST`;
export const ADD_CATALOG_LABOR_TIME = `${ESTIMATE_NAMESPACE}/ADD_CATALOG_LABOR_TIME`;
export const ADD_CATALOG_REFERENCE = `${ESTIMATE_NAMESPACE}/ADD_CATALOG_REFERENCE`;
export const ADD_CATALOG_TIRE = `${ESTIMATE_NAMESPACE}/ADD_CATALOG_TIRE`;
export const ADD_CUSTOM_REFERENCE = `${ESTIMATE_NAMESPACE}/ADD_CUSTOM_REFERENCE`;
export const ADD_CUSTOM_TIRE = `${ESTIMATE_NAMESPACE}/ADD_CUSTOM_TIRE`;
export const ADD_CUSTOM_LABOR_TIME = `${ESTIMATE_NAMESPACE}/ADD_CUSTOM_LABOR_TIME`;
export const ADD_CUSTOM_WASTE_RECYCLING = `${ESTIMATE_NAMESPACE}/ADD_CUSTOM_WASTE_RECYCLING`;
export const ADD_CUSTOM_FREE_BUNDLE = `${ESTIMATE_NAMESPACE}/ADD_CUSTOM_FREE_BUNDLE`;
export const ADD_OTHER_ITEM = `${ESTIMATE_NAMESPACE}/ADD_OTHER_ITEM`;
export const ADD_CATALOG_REFERENCE_BY_REFERENCE_NUMBER = `${ESTIMATE_NAMESPACE}/ADD_CATALOG_REFERENCE_BY_REFERENCE_NUMBER`;
export const ADD_CATALOG_TIRE_BY_REFERENCE_NUMBER = `${ESTIMATE_NAMESPACE}/ADD_CATALOG_TIRE_BY_REFERENCE_NUMBER`;
export const ADD_BUNDLES_FROM_MY_STORE = `${ESTIMATE_NAMESPACE}/ADD_BUNDLES_FROM_MY_STORE`;
export const ADD_REFERENCE_NUMBER = `${ESTIMATE_NAMESPACE}/ADD_REFERENCE_NUMBER`;
export const ADD_CATALOG_LABOR_TIME_BY_CODE = `${ESTIMATE_NAMESPACE}/ADD_CATALOG_LABOR_TIME_BY_CODE`;

export const UPDATE_REFERENCE = `${ESTIMATE_NAMESPACE}/UPDATE_REFERENCE`;
export const UPDATE_TIRE = `${ESTIMATE_NAMESPACE}/UPDATE_TIRE`;
export const UPDATE_LABOR_TIME = `${ESTIMATE_NAMESPACE}/UPDATE_LABOR_TIME`;
export const UPDATE_OTHER_ITEM = `${ESTIMATE_NAMESPACE}/UPDATE_OTHER_ITEM`;
export const UPDATE_FREE_BUNDLE = `${ESTIMATE_NAMESPACE}/UPDATE_FREE_BUNDLE`;
export const UPDATE_WASTE_RECYCLING = `${ESTIMATE_NAMESPACE}/UPDATE_WASTE_RECYCLING`;
export const ADD_VEHICLE = `${ESTIMATE_NAMESPACE}/ADD_VEHICLE`;
export const UPDATE_VEHICLE = `${ESTIMATE_NAMESPACE}/UPDATE_VEHICLE`;
export const UPDATE_CLIENT = `${ESTIMATE_NAMESPACE}/UPDATE_CLIENT`;
export const UPDATE_OBSERVATIONS = `${ESTIMATE_NAMESPACE}/UPDATE_OBSERVATIONS`;

export const REMOVE_REFERENCE = `${ESTIMATE_NAMESPACE}/REMOVE_REFERENCE`;
export const REMOVE_TIRE = `${ESTIMATE_NAMESPACE}/REMOVE_TIRE`;
export const REMOVE_LABOR_TIME = `${ESTIMATE_NAMESPACE}/REMOVE_LABOR_TIME`;
export const REMOVE_OTHER_ITEM = `${ESTIMATE_NAMESPACE}/REMOVE_OTHER_ITEM`;
export const REMOVE_FREE_BUNDLE = `${ESTIMATE_NAMESPACE}/REMOVE_FREE_BUNDLE`;
export const REMOVE_WASTE_RECYCLING = `${ESTIMATE_NAMESPACE}/REMOVE_WASTE_RECYCLING`;
export const REMOVE_ALL_REFERENCES = `${ESTIMATE_NAMESPACE}/REMOVE_ALL_REFERENCES`;
export const REMOVE_ALL_TIRES = `${ESTIMATE_NAMESPACE}/REMOVE_ALL_TIRES`;
export const REMOVE_ALL_LABOR_TIMES = `${ESTIMATE_NAMESPACE}/REMOVE_ALL_LABOR_TIMES`;
export const REMOVE_ALL_WASTE_RECYCLING = `${ESTIMATE_NAMESPACE}/REMOVE_ALL_WASTE_RECYCLING`;
export const REMOVE_ALL_FREE_BUNDLES = `${ESTIMATE_NAMESPACE}/REMOVE_ALL_FREE_BUNDLES`;
export const REMOVE_ALL_OTHER_ITEMS = `${ESTIMATE_NAMESPACE}/REMOVE_ALL_OTHER_ITEMS`;
export const REMOVE_KNOWN_REFERENCE_CONFIRMATION = `${ESTIMATE_NAMESPACE}/REMOVE_KNOWN_REFERENCE_CONFIRMATION`;

export const GET_HISTORY = `${ESTIMATE_NAMESPACE}/GET_HISTORY`;
export const GET_SETTINGS = `${ESTIMATE_NAMESPACE}/GET_SETTINGS`;
export const UPDATE_SETTINGS = `${ESTIMATE_NAMESPACE}/UPDATE_SETTINGS`;
export const UPDATE_WASTE_RECYCLING_SETTINGS = `${ESTIMATE_NAMESPACE}/UPDATE_WASTE_RECYCLING_SETTINGS`;
export const ADD_CUSTOM_SETTING = `${ESTIMATE_NAMESPACE}/ADD_CUSTOM_SETTING`;
export const REMOVE_CUSTOM_SETTING = `${ESTIMATE_NAMESPACE}/REMOVE_CUSTOM_SETTING`;
export const CREATE_ESTIMATE_FROM_DMS = `${ESTIMATE_NAMESPACE}/CREATE_ESTIMATE_FROM_DMS`;
export const SEND_ESTIMATE_TO_DMS = `${ESTIMATE_NAMESPACE}/SEND_ESTIMATE_TO_DMS`;
export const DELETE_ESTIMATE = `${ESTIMATE_NAMESPACE}/DELETE_ESTIMATE`;
export const CANCEL_ESTIMATE_DELETION = `${ESTIMATE_NAMESPACE}/CANCEL_ESTIMATE_DELETION`;

export const FILL_FROM_MAINTENANCE_PLAN = `${ESTIMATE_NAMESPACE}/FILL_FROM_MAINTENANCE_PLAN`;

export const EstimateIdParam = 'estimateId';
export const EstimateTabParam = 'tab';
export const EstimateTabName = 'estimate';
export const HistoryTabName = 'history';
export const SettingsTabName = 'settings';
export const FocusSetting = 'focusSetting';

export type ESTIMATE_TAB = typeof EstimateTabName | typeof HistoryTabName | typeof SettingsTabName;

export interface EstimateState {
  currentEstimateId: string;
  estimates: Map<string, EstimateLocal | NO_DATA>;
  history: EstimateHistory;
  settings: Settings | NO_DATA;
  nonApplicableReferencesConfirmations: string[];
  selectedTab: ESTIMATE_TAB;
  estimateLogo: string | undefined;
}

export interface EstimateHistory {
  cursor?: string;
  hasMore: boolean;
  search?: string;
  estimates: HistoryEstimateLocal[];
  searchStatus: SEARCH_STATUS;
}

export interface HistoryEstimateLocal extends HistoryEstimate {
  isHidden: boolean;
}

export interface ReferenceDiscountMargins {
  marginDiscount: number;
}

export interface EstimateLocal {
  estimateId: string;
  creationDate: string;
  sequenceNumber?: string;
  clientContact: ClientContact;
  totalPrice: TotalPrice;
  vehicle?: EstimateVehicle;
  observations?: string;
  dmsOrderNumber?: string;
  referenceSubsection: ReferenceSection;
  tireSubsection: TireSection;
  laborTimeSubsection: LaborTimeSection;
  wasteRecyclingSubsection: WasteRecyclingSection;
  freeBundleSubsection: FreeBundleSection;
  otherItemSubsection: OtherItemSection;
  totalMargin: string;
  totalMarginPercentage: string;
  referenceDiscountMarginMap: Map<string, ReferenceDiscountMargins>;
  isHidden: boolean;
}

export interface RequestReference extends Omit<Reference, 'linkedReferences'> {
  linkedReferences?: LinkedReference[];
}

export interface EstimateVehicle extends Vehicle {
  dialogAddToEstimateWasShown?: boolean;
}
