import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { TechnicalDataL1 } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/model/TechnicalDataL1';
import { TechnicalDataL2 } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/model/TechnicalDataL2';
import { TechnicalDataL3 } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/model/TechnicalDataL3';
import { RootState } from 'app/AppStore';
import { useFetchIAMTechnicalDataDetails } from 'domains/catalog/Catalog.requests';
import {
  getVehicleTechnicalDataCategoryL3Data,
  getVehicleTechnicalDataCategoryL3Ids,
} from 'domains/catalog/Catalog.store';
import { INTERVALS_TAB, MaintenanceTabsType } from 'domains/catalog/Catalog.types';
import { Flex, MarginBox, Pipeline, Text } from 'UI';
import { MaintenanceCard, SectionHeader, SectionContent } from './MaintenancePlan.styled';

interface MaintenancePlanTableProps {
  tab: MaintenanceTabsType;
  technicalData: TechnicalDataL1 | undefined;
}

const MaintenancePlanTable = ({ tab, technicalData }: MaintenancePlanTableProps) => {
  const { t } = useTranslation();
  const categoryL3Ids = useSelector((state: RootState) =>
    getVehicleTechnicalDataCategoryL3Ids(state, technicalData?.id),
  );

  useFetchIAMTechnicalDataDetails(categoryL3Ids ?? [], technicalData?.id);

  if (!technicalData) {
    return <></>;
  }

  const L2Section = ({ l2 }: { l2: TechnicalDataL2 }) => {
    return (
      <>
        <SectionHeader>
          <Flex align={'center'} minHeight={54}>
            <Flex>
              <MarginBox mx={25}>
                <Text type={'h5_bold'} disableGutter>
                  {l2.label}
                </Text>
              </MarginBox>
            </Flex>
            {tab === INTERVALS_TAB && (
              <Flex justify={'center'}>
                <Text type={'h5_bold'} disableGutter>
                  {t('catalog.maintenance.carry_out', 'Carry out')}
                </Text>
              </Flex>
            )}
          </Flex>
        </SectionHeader>
        {l2.l3List.map((l3, index) => {
          return <L3DataLine l3={l3} key={`${l3.id}-${index}`} />;
        })}
      </>
    );
  };

  return (
    <>
      <MaintenanceCard active={false} data>
        <Flex direction={'column'} align={'center'} justify={'center'}>
          {technicalData.l2List.map((l2, index) => {
            return <L2Section l2={l2} key={index} />;
          })}
        </Flex>
      </MaintenanceCard>
    </>
  );
};

const L3DataLine = ({ l3 }: { l3: TechnicalDataL3 }) => {
  const l3categoryData = useSelector((state: RootState) => getVehicleTechnicalDataCategoryL3Data(state, l3.id));
  const l3Data = l3categoryData?.technicalDataContentList;
  return (
    <>
      <SectionContent direction={'column'}>
        {l3Data?.map((l3d, index) => {
          return (
            <React.Fragment key={index}>
              <Flex direction={'column'}>
                <Flex align={'center'} minHeight={54}>
                  <Flex size={3}>
                    <MarginBox mx={25}>{`${l3.label} ${l3d.variant ?? ''}`}</MarginBox>
                  </Flex>
                  <Flex justify={'center'} size={2}>
                    <Flex>
                      <MarginBox mx={15}>{`${l3d.value} ${l3d.unit ?? ''}`}</MarginBox>
                    </Flex>
                    {l3d.additionalInformation && (
                      <Flex>
                        <MarginBox mx={15}>{l3d.additionalInformation}</MarginBox>
                      </Flex>
                    )}
                  </Flex>
                </Flex>
              </Flex>
              <Pipeline size={'100%'} horizontal />
            </React.Fragment>
          );
        })}
      </SectionContent>
    </>
  );
};

export default MaintenancePlanTable;
