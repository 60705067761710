import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { getLastSearchVehicleTechnicalData } from 'domains/catalog/Catalog.store';
import {
  IamCatalogTabsType,
  PARAM_CONSUMABLES,
  TECHNICAL_DATA_TAB,
  TechnicalDataIdParam,
} from 'domains/catalog/Catalog.types';
import { VehicleTechnicalDataSection } from 'pages/CatalogPage/IAM/VehicleTechnicalData/VehicleTechnicalDataSection';
import { Container, Flex, LinkTabs } from 'UI';

export const VehicleTechnicalData = ({ tabKey }: { tabKey: IamCatalogTabsType }) => {
  const history = useHistory();
  //eslint-disable-next-line
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const technicalData = useSelector((state: RootState) => getLastSearchVehicleTechnicalData(state));
  const activeTab = params.get(TechnicalDataIdParam);

  useEffect(() => {
    if (!activeTab && technicalData && tabKey === TECHNICAL_DATA_TAB) {
      const hasConsumablesTab = technicalData.find((item) => item.id === PARAM_CONSUMABLES);
      if (hasConsumablesTab) {
        params.set(TechnicalDataIdParam, PARAM_CONSUMABLES);
      } else if (technicalData.length > 0) {
        params.set(TechnicalDataIdParam, technicalData[0].id);
      }
      history.replace(`${location.pathname}?${params}`);
    }
  }, [tabKey, activeTab, params, history, technicalData]);

  if (!technicalData) return null;

  function getParamIndex(technicalDataIndex: string) {
    const urlParams = new URLSearchParams();
    urlParams.set(TechnicalDataIdParam, technicalDataIndex);
    return urlParams;
  }

  const tabItems = technicalData.map((item, idx) => {
    return {
      key: idx,
      label: item.label,
      uparam: getParamIndex(item.id),
      link: '',
    };
  });

  return (
    <Container>
      <Flex direction={'column'} gap={30}>
        <LinkTabs items={tabItems} urlParam={TechnicalDataIdParam} />
        {activeTab && <VehicleTechnicalDataSection categoryId={activeTab} />}
      </Flex>
    </Container>
  );
};
