import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export type FloatingCardProps = PropsWithChildren;

// TODO temporary until Search History is implemented
export const FloatingCard = styled.div<{ large?: boolean } & FloatingCardProps>`
  background-color: white;
  height: 50px;
  width: 690px;
  border-radius: 25px 25px 4px 4px;
  box-shadow: 0 10px 10px 0 ${({ theme }) => theme.color.grey75};
  border: solid 1px #e4e4e4;
  padding: 0 60px 30px 60px;
  z-index: 801;
  transition: height 0.2s;
  user-select: none;
`;

export const FloatingContent = styled.div<FloatingCardProps>`
  display: none;
`;
