import React, { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { InputRef } from 'antd';
import { Dispatch } from 'redux';
import { SearchIcon } from 'assets/icons';
import { TireSeasonSelection } from 'components/TireSeasonSelection/TireSeasonSelection';
import { toSearchParams } from 'domains/tires/Tire.mapper';
import {
  getFulltextSearchData,
  getTireSearchQuery,
  setFulltextSearchData,
  setSearchParams,
  setTireSearchQuery,
  tireProductsSearchRequestSaga,
  tiresFulltextSearchRequestSaga,
} from 'domains/tires/Tire.store';
import { TireBrandSelection } from 'pages/TiresPage/TireSearch/TireBrandSelection';
import { TireSearchProps } from 'pages/TiresPage/TireSearch/TireSearch';
import { TiresFulltextSearchResults } from 'pages/TiresPage/TireSearch/TiresSearchbar/TiresFulltextSearchResults';
import { SContainer, SearchButton } from 'pages/TiresPage/TireSearch/TiresSearchbar/TiresSearchbar.styled';
import { Box, Flex, Icon, Input, MarginBox, Pipeline, Text } from 'UI';
import { useFocusOnMount, useLarge } from 'utils';
import { useOutsideClick } from 'utils/hooks/useOutsideClick';

interface QueryWork {
  regex: RegExp;
  addSpace: (val: string) => boolean;
}

const lastTwoNumbersRegex = new RegExp(/^\d{2}$/);
const ndRegex = new RegExp(/^[Nn][Dd]$/);
const whiteSpaceRegex = new RegExp(/\s{1,2}$/);

const queryWorkArr: QueryWork[] = [
  {
    regex: new RegExp(/^\d{0,3}$/),
    addSpace: (val: string) => {
      return val.length === 3;
    },
  },
  {
    regex: new RegExp(/^\d{0,2}$/),
    addSpace: (val: string) => {
      return val.length === 2;
    },
  },
  {
    regex: new RegExp(/^[ZzRr]?[Rr]?\d{0,2}$/),
    addSpace: (val: string) => {
      if (val.length < 2) return false;
      return lastTwoNumbersRegex.test(val.slice(-2));
    },
  },
  {
    regex: new RegExp(/^(?![Dd])[Nn]?[Dd]?$|^\d{0,3}$/),
    addSpace: (val: string) => {
      return ndRegex.test(val) || val.length === 3;
    },
  },
  {
    regex: new RegExp(/^[A-Za-z]?$/),
    addSpace: () => {
      return false;
    },
  },
];

function addEndSpace(arrQueries: string[]): boolean {
  if (arrQueries.length < 1 || arrQueries.length > queryWorkArr.length) return false;
  const last = arrQueries.length - 1;
  return queryWorkArr[last].addSpace(arrQueries[last]);
}

function checkCorrectInput(arrQueries: string[]): boolean {
  if (arrQueries.length > queryWorkArr.length) return false;
  for (let i = 0; i < arrQueries.length; i++) {
    if (!queryWorkArr[i].regex.test(arrQueries[i])) return false;
  }
  return true;
}

const setQuery = (queryValue: string, dispatch: Dispatch) => {
  dispatch(setTireSearchQuery({ query: queryValue }));
};

export const TiresSearchbar = ({ brands, setBrands, season, setSeason }: TireSearchProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const large = useLarge();
  const query = useSelector(getTireSearchQuery);
  const [menuOpen, setMenuOpen] = useState(false);
  const [queryDebounced, setQueryDebounced] = useState('');

  const fulltextSearchData = useSelector(getFulltextSearchData).data;
  const searchTyresRef = useRef<InputRef>(null);
  useFocusOnMount<InputRef>(searchTyresRef);

  const closeFulltextSearchResults = useCallback(() => {
    if (fulltextSearchData?.length) {
      dispatch(setFulltextSearchData([]));
    }
  }, [dispatch, fulltextSearchData]);

  const ref = useOutsideClick<HTMLDivElement>(closeFulltextSearchResults);

  function fulltextSearch(val: string) {
    const trimQuery = query.trim();
    const trimVal = val.trim();
    if (trimVal.length > 2 && trimQuery === trimVal) {
      dispatch(tiresFulltextSearchRequestSaga({ fulltextSearch: trimVal }));
      setQueryDebounced(trimVal);
    }
  }

  const handleSearch = useCallback(
    (searchValue: string) => {
      const selected = brands.filter((b) => b.selected);
      if (selected) {
        dispatch(
          tireProductsSearchRequestSaga({
            query: searchValue,
            season,
            brands: selected.map((brand) => brand.brandId),
          }),
        );
        dispatch(
          setSearchParams({
            searchParamsBase64: toSearchParams(
              searchValue,
              season,
              selected.map((brand) => brand.brandId),
            ).searchParamsBase64,
          }),
        );
      }
      setQuery(searchValue, dispatch);
      closeFulltextSearchResults();
    },
    [dispatch, season, brands, closeFulltextSearchResults],
  );

  const handleKeyPress = useCallback(
    (event: { key: string }) => {
      if (event.key === 'Enter') {
        if (query.split(' ').length < 4) {
          return;
        }
        handleSearch(query);
      }
    },
    [query, handleSearch],
  );

  return (
    <SContainer error={false} justify={'center'} minWidth={large ? 1000 : 900}>
      <Flex align={'center'} size={0} minWidth={115} direction={'row'} justify={'space-between'}>
        <Box width={115}>
          <MarginBox mx={10}>
            <TireSeasonSelection
              season={season}
              setSeason={(newSeason) => setSeason(newSeason)}
              menuOpen={menuOpen}
              setMenuOpen={(isOpen) => setMenuOpen(isOpen)}
              translateX={-76}
              translateY={6}
            />
          </MarginBox>
        </Box>
        <Pipeline size={30} />
      </Flex>
      <Flex align={'center'} size={'0 1 195px'} minWidth={170}>
        <TireBrandSelection brands={brands} setBrands={setBrands} translateY={6} />
        <Pipeline size={30} />
      </Flex>
      <Flex align={'center'} minWidth={195}>
        <Box width={'100%'} ref={ref}>
          <Input
            value={query}
            onChange={(val) => {
              const trimVal =
                val.length <= 12 && val.length > query.length && whiteSpaceRegex.test(val) ? val.slice(0, -1) : val;
              const arrQueries = trimVal.split(' ');
              if (!checkCorrectInput(arrQueries)) return;
              const addSpace = addEndSpace(arrQueries);
              if (trimVal.length > query.length) {
                setQuery(arrQueries.join(' ') + (addSpace ? ' ' : ''), dispatch);
              } else {
                setQuery(arrQueries.join(' ').substring(0, trimVal.length - (addSpace ? 1 : 0)), dispatch);
              }
            }}
            onChangeDebounced={fulltextSearch}
            onKeyPress={handleKeyPress}
            passRef={searchTyresRef}
            placeholder={t('catalog.tires.search.placeholder', 'Please type your reference or tires size here…')}
            dataCy={'tire search'}
          />
          <TiresFulltextSearchResults search={queryDebounced} onClickOnSelectedValue={handleSearch} />
        </Box>
      </Flex>
      <SearchButton disabled={query.split(' ').length < 4} onClick={() => handleSearch(query)}>
        <Flex align={'center'} justify={'center'}>
          <Icon IconComponent={SearchIcon} ml={5} mr={10} size={32} color={'black'} />
          <Text type={'h6'}>{t('catalog.tires.search', 'Search')}</Text>
        </Flex>
        <MarginBox mr={5} />
      </SearchButton>
    </SContainer>
  );
};
