import { Season } from '@1po/1po-bff-fe-spec/generated/tire/model/Season';
import { ALL, SUMMER, SUMMER_WINTER, WINTER } from 'domains/tires/Tire.types';

interface SearchParams {
  searchParamsBase64: string;
  width: string;
  series: string;
  diameter: string;
  chargeIndicator: string;
  speedIndicator: string;
}

export const toSearchParams = (query: string, season: string | undefined, brands: string[]): SearchParams => {
  const params = query.split(' ');
  const width = params[0] ?? '';
  const series = params[1] ?? '';
  const diameter = params[2] ?? '';
  const chargeIndicator = params.length >= 4 ? params[3] : '';
  const speedIndicator = params.length === 5 ? params[4] : '';

  const allBrands = brands.join('-');
  const tireSearchParams = `${width}-${series}-${diameter}-${chargeIndicator}-${speedIndicator}`.toUpperCase();
  const allRequestParams = `${allBrands}-${season}-${tireSearchParams}`;

  return {
    searchParamsBase64: Buffer.from(allRequestParams).toString('base64'),
    width,
    series,
    diameter,
    chargeIndicator,
    speedIndicator,
  };
};

function getQueryWithMaxLoadIndex(
  regExp: RegExpMatchArray | undefined,
  query: string,
  separator: string,
): string | undefined {
  if (!regExp || regExp.index === undefined) {
    return undefined;
  }
  const availableLoadIndexes = regExp[0]?.split(separator) ?? [];
  const maxLoadIndex =
    availableLoadIndexes.length >= 1
      ? Math.max(Number(availableLoadIndexes[0]), Number(availableLoadIndexes[1]))
      : undefined;
  return query.substring(0, regExp.index) + maxLoadIndex + query.substring(regExp.index + regExp[0].length);
}

export function convertDHTireTechnicalDesignationToSearchQuery(
  technicalDesignation: string | undefined,
): string | undefined {
  if (!technicalDesignation) {
    return undefined;
  }
  const query = technicalDesignation.replace('/ ', '').replace('R ', 'R').replace('Z ', 'Z');
  return getQueryWithMaxLoadIndex(query.match(/\d+,\d+/) ?? undefined, query, ',') ?? query;
}

export function convertDHTireSizeToSearchQuery(technicalDesignation: string | undefined): string | undefined {
  if (!technicalDesignation) {
    return undefined;
  }
  const query = technicalDesignation
    .split(',')?.[0]
    ?.replace('/', ' ')
    .replace(')', ' ')
    .replace('(', '')
    .replace('CP', '')
    .replace('C', '');
  return getQueryWithMaxLoadIndex(query.match(/\d+[/]\d+/) ?? undefined, query, '/') ?? query;
}

export function convertIAMTireSizeToSearchQuery(tireSize: string | undefined): string | undefined {
  if (!tireSize) {
    return undefined;
  }

  const lastChar = tireSize.at(-1) ?? '';
  if (lastChar?.match(/[a-zA-Z]/)) {
    tireSize = tireSize.replace(lastChar, ` ${lastChar}`);
  }

  return tireSize.replace('/', ' ').replace('R', ' R').replace('Z', ' Z');
}

export function mapDHTireUsageToSeason(usage: string | undefined): Season {
  switch (usage) {
    case 'Summer':
      return SUMMER;
    // TODO Not sure about winter and summer_winter
    case 'Winter':
      return WINTER;
    case 'Summer and winter':
      return SUMMER_WINTER;
    default:
      return ALL;
  }
}

export function mapIAMTireUsageToSeason(usage: string | undefined): Season {
  switch (usage) {
    case 'Summer and winter':
      return SUMMER_WINTER;
    case 'winter only':
      return WINTER;
    // TODO Not sure about Summer
    case 'Summer':
      return SUMMER;
    default:
      return ALL;
  }
}
