import React, { FocusEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { ROUTER_CATALOG_VEHICLE, ROUTER_PRODUCT } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { fetchTextSearch } from 'domains/catalog/Catalog.requests';
import {
  fetchFulltextAutocompleteRequestSaga,
  getFulltextAutocomplete,
  getLastSearchedVehicleKey,
  getLastVehicleDetail,
  getSearchStatus,
  setEmptyFulltextAutocomplete,
} from 'domains/catalog/Catalog.store';
import { SearchParams } from 'domains/catalog/Catalog.types';
import { getCatalogSourceUrl, getIAMCatalogSourcePaths } from 'domains/catalog/Catalog.utils';
import { getDHReference } from 'domains/references';
import { getUserDisplayMode } from 'domains/user';
import { FOUND, getData, hasData, isLoading, NOT_FOUND, useFocus } from 'utils';
import AutocompleteMenu from './AutocompleteMenu';
import { SearchContainer, SSearchInput } from './Search.styled';
import { notifySearchError } from './SearchErrorAlert';

export const referenceRegex = new RegExp('^[\\da-zA-Z]{10,15}$');
export const isQueryReferenceNumber = (query: string) => query.length === 10 && referenceRegex.test(query);

const SearchInputID = 'search-reference-fulltext-id';
const SearchInputName = 'search-reference-fulltext-name';

const Search = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const theme = useTheme();
  const userDisplayMode = useSelector(getUserDisplayMode);
  const inputRef = React.useRef<any>(null);
  const menuRef = useRef<any>(null);
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const vehicleDetail = useSelector(getLastVehicleDetail);

  const [search, setSearch] = useState<SearchParams>({
    query: '',
    vehicleKey,
    pending: false,
    isValid: false,
    open: false,
    focusMenu: undefined,
    searchId: undefined,
  });
  const [loadingQuery, setLoadingQuery] = useState<string | undefined>(undefined);
  const [inputValue, setInputValue] = useState('');

  const results = useSelector((state: RootState) => getFulltextAutocomplete(state, vehicleKey ?? ''));
  const resultsLength = getData(results)?.length ?? 0;
  const resultReference = useSelector((state: RootState) =>
    getDHReference(state, { vehicleKey, referenceNumber: loadingQuery }),
  );
  const searchStatus = useSelector((state: RootState) => getSearchStatus(state));
  const isSearchValid = useCallback(
    () =>
      search?.searchId === searchStatus?.searchId &&
      search.query?.toUpperCase() === searchStatus?.searchText.toUpperCase() &&
      search.vehicleKey === searchStatus?.vehicleKey,
    [search, searchStatus],
  );

  useFocus(inputRef, undefined);

  // @TODO extract to useFocus
  useEffect(() => {
    if (typeof search.focusMenu === 'number') {
      document.getElementById(`search-menu-${search.focusMenu}`)?.focus();
    }
  }, [search.focusMenu]);

  useEffect(() => {
    if (vehicleDetail?.catalogSource === 'IAM') return;
    if (search && !search.pending && search.query && search.isValid) {
      if (search.vehicleKey && !isQueryReferenceNumber(search.query)) {
        dispatch(
          fetchFulltextAutocompleteRequestSaga({
            vehicleKey: search.vehicleKey,
            searchText: search.query,
          }),
        );
      }
      setSearch({ ...search, pending: true });
    }
    if (search && !search.query && search.vehicleKey) {
      dispatch(setEmptyFulltextAutocomplete({ vehicleKey: search.vehicleKey }));
    }
  }, [dispatch, search, vehicleDetail]);

  const handleFullTextResult = useCallback(() => {
    if (!searchStatus) return;

    if (searchStatus.status === FOUND && search.query && search.query?.length > 0 && searchStatus.source === 'IAM') {
      const vehKey = `/${vehicleKey}`;
      history.push(
        `${getIAMCatalogSourcePaths(vehicleKey ? vehKey : '')}${ROUTER_CATALOG_VEHICLE}/search/${encodeURIComponent(
          search.query,
        )}`,
      );
      setSearch({ ...search, searchId: undefined });
      return;
    }

    if (!vehicleKey || !vehicleDetail?.catalogSource) {
      if (searchStatus.status === NOT_FOUND) {
        notifySearchError({ type: 'reference', t });
        setSearch({ ...search, searchId: undefined });
      }
      return;
    }

    if (searchStatus.status === FOUND && search.query && search.query?.length > 0 && search.pending) {
      if (searchStatus.source === 'DATAHUB') {
        dispatch(setEmptyFulltextAutocomplete({ vehicleKey }));
        history.push(
          `${getCatalogSourceUrl(
            vehicleDetail.catalogSource,
          )}/${vehicleKey}${ROUTER_CATALOG_VEHICLE}/search/${encodeURIComponent(search.query)}`,
        );
        setSearch({ ...search, searchId: undefined });
        return;
      }
    }

    if (searchStatus.status === NOT_FOUND) {
      notifySearchError({ type: 'fullText', t });
      setSearch({ ...search, searchId: undefined });
    }
  }, [searchStatus, search, vehicleKey, vehicleDetail?.catalogSource, history, t, dispatch]);

  const handleReferenceResult = useCallback(() => {
    if (!searchStatus) return;

    if (searchStatus?.source === 'DATAHUB') {
      if (searchStatus.status === FOUND && hasData(resultReference)) {
        if (vehicleKey && vehicleDetail?.catalogSource === 'DATAHUB') {
          history.push(
            `${getCatalogSourceUrl(
              vehicleDetail.catalogSource,
            )}/${vehicleKey}${ROUTER_CATALOG_VEHICLE}${ROUTER_PRODUCT}/${resultReference.referenceNumber.toUpperCase()}`,
          );
        } else {
          history.push(`${ROUTER_PRODUCT}/${resultReference.referenceNumber.toUpperCase()}`);
        }
        setSearch({ ...search, searchId: undefined });
        setLoadingQuery(undefined);
      }
      if (searchStatus.status === NOT_FOUND) {
        notifySearchError({ type: 'reference', t });
        setSearch({ ...search, searchId: undefined });
        setLoadingQuery(undefined);
      }
    }
  }, [searchStatus, resultReference, vehicleKey, vehicleDetail?.catalogSource, search, history, t]);

  useEffect(() => {
    if (!searchStatus || !isSearchValid()) return;
    if (searchStatus?.type === 'FULLTEXT') {
      handleFullTextResult();
    }
    if (searchStatus?.type === 'REFERENCE') {
      handleReferenceResult();
    }
  }, [t, isSearchValid, handleFullTextResult, handleReferenceResult, searchStatus]);

  const onDebouncedChange = useCallback(
    (value: string) => {
      setSearch(
        value?.length > 2
          ? {
              ...search,
              query: value.toLowerCase(),
              vehicleKey,
              pending: false,
              isValid: true,
              open: true,
              focusMenu: undefined,
            }
          : {
              ...search,
              query: '',
              vehicleKey,
              pending: false,
              isValid: false,
              open: false,
              focusMenu: undefined,
            },
      );
    },
    [search, vehicleKey],
  );

  const handleClick = useCallback(
    (label: string) => () => {
      if (!label) {
        return;
      }
      const searchId = uuidv4();
      setInputValue(label);
      setSearch({
        query: label,
        vehicleKey,
        pending: false,
        isValid: true,
        open: false,
        focusMenu: undefined,
        searchId,
      });

      fetchTextSearch(dispatch, label, searchId, vehicleKey, vehicleDetail?.catalogSource);
      setLoadingQuery(label.toUpperCase());
    },
    [vehicleKey, dispatch, vehicleDetail?.catalogSource],
  );

  const handleMove = useCallback(
    (offset: number) => {
      if (search.open) {
        setSearch({
          ...search,
          focusMenu: search?.focusMenu ? (search.focusMenu + offset) % resultsLength : 0,
        });
      }
    },
    [resultsLength, search],
  );

  const handleDown = useCallback(() => handleMove(1), [handleMove]);
  const handleUp = useCallback(() => handleMove(-1), [handleMove]);

  const handleKeyPress = useCallback(
    (event: { key: string }) => {
      if (event.key === 'Escape') {
        setSearch({ ...search, open: false, focusMenu: 0 });
      }
      if (event.key === 'ArrowUp') {
        handleUp();
      }
      if (event.key === 'ArrowDown') {
        handleDown();
      }
    },
    [handleDown, handleUp, search, setSearch],
  );

  const handleBlurInput = (_val: any, e?: FocusEvent) => {
    if (
      search &&
      search.open &&
      search.focusMenu === undefined &&
      !(e?.relatedTarget && e?.relatedTarget.id.includes('search-menu'))
    ) {
      setSearch({ ...search, open: false });
    }
  };

  const handleQueryInputValue = handleClick(inputValue.trim());

  // @TODO
  // animation
  // map error
  return (
    <SearchContainer>
      <SSearchInput
        id={SearchInputID}
        name={SearchInputName}
        autoComplete={`on section-${SearchInputName}`}
        value={inputValue}
        onChange={(val) => setInputValue(val.toLowerCase())}
        onChangeDebounced={onDebouncedChange}
        onClick={handleQueryInputValue}
        onPressEnter={handleQueryInputValue}
        onKeyPress={handleKeyPress}
        onBlur={handleBlurInput}
        isLoading={isLoading(searchStatus?.status)}
        displayMode={userDisplayMode}
        color={theme.displayMode[userDisplayMode].searchFontColor}
        passRef={inputRef}
        placeholder={
          vehicleKey
            ? t('catalog.parts.search_by_reference.title_keywords', 'Search by reference or keywords')
            : t('catalog.parts.search.by_reference', 'Search by reference...')
        }
      />
      {results && resultsLength > 0 && hasData(results) && search && search.open && (
        <AutocompleteMenu
          results={results}
          search={search}
          setSearch={setSearch}
          onClick={handleClick}
          inputValue={inputValue}
          menuRef={menuRef}
        />
      )}
    </SearchContainer>
  );
};

export default Search;
