import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Menu } from 'antd';
import { GlobeIcon } from 'assets/icons';
import { changeWebLanguageSaga, getWebLanguage } from 'domains/user';
import { Icon } from 'UI';
import { Country } from 'utils/i18n/Country';
import { Language } from 'utils/i18n/Language';
import { NoSelect } from './LanguageMenu.styled';

const LanguageMenu = () => {
  const dispatch = useDispatch();
  const currentLang = useSelector(getWebLanguage);

  // TODO extract functionality
  const onLanguageChange = (e: { key: string }) => {
    const selectedLang = Language.findInWebLang(e.key);
    if (Language.languagesAreEqual(selectedLang, currentLang)) {
      return;
    }
    if (selectedLang) {
      dispatch(changeWebLanguageSaga(selectedLang));
    }
  };

  const renderLanguage = ({ key, name, country }: Language) => (
    <Menu.Item key={key} disabled={key === currentLang.key}>
      <Icon IconComponent={country instanceof Country ? country.icon : GlobeIcon} size={14} color={'black'} mr={15} />
      <NoSelect>{name}</NoSelect>
    </Menu.Item>
  );

  const menu = (
    <Menu onClick={onLanguageChange}>
      {Language.listWebLanguages()
        .sort((l1, l2) => l1.name.localeCompare(l2.name))
        .map(renderLanguage)}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement={'bottom'} trigger={['click']} data-cy={'dropdown-language'}>
      <a className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
        <Icon
          IconComponent={currentLang.country instanceof Country ? currentLang.country.icon : GlobeIcon}
          size={14}
          color={'black'}
          mr={10}
        />
        <NoSelect>{currentLang.name}</NoSelect>
      </a>
    </Dropdown>
  );
};

export default LanguageMenu;
