import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { VehicleOriginalFactoryTire } from '@1po/1po-bff-fe-spec/generated/tire/model/VehicleTires';
import { TiresBothAxlesIcon, TiresFrontAxleIcon, TiresRearAxleIcon } from 'assets/icons';
import { ErrorWithLabel } from 'components/DataContainer';
import TireReferencesPopup from 'components/TireReferencesPopup';
import {
  convertDHTireTechnicalDesignationToSearchQuery,
  mapDHTireUsageToSeason,
  toSearchParams,
} from 'domains/tires/Tire.mapper';
import { tireProductsSearchRequestSaga } from 'domains/tires/Tire.store';
import { ALL } from 'domains/tires/Tire.types';
import { SCard } from 'pages/CatalogPage/DH/Tires/Tires.styled';
import { tireBrandsData } from 'pages/TiresPage/TireSearch/TiresSearchbar/TireBrandsData';
import { Flex, Icon, Image, ImageWithLoader, MarginBox, Text } from 'UI';

const InfoLine = ({ title, data }: { title: React.ReactNode; data: string | undefined }) => {
  return (
    <MarginBox ml={15}>
      <Flex>
        <Flex minWidth={170}>
          <Text type={'text_dim_bold'}>{title}</Text>
        </Flex>
        <Flex minHeight={30} minWidth={155}>
          <Text type={'light_14_black_85'}>{data}</Text>
        </Flex>
      </Flex>
    </MarginBox>
  );
};

interface TireCardProps {
  tire: VehicleOriginalFactoryTire;
  isSelected: boolean;
  setSelectedTire: (tire: string | undefined) => void;
}

const TireCard = ({ tire, isSelected, setSelectedTire }: TireCardProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const season = ALL;
  const allBrandsId = tireBrandsData.map((brand) => brand.brandId);
  const originalFactoryTireSeason = mapDHTireUsageToSeason(tire.season);
  const brandFilter = tireBrandsData.find((tireBrand) => tire.brand !== 'null' && tireBrand.name === tire.brand)?.name;
  const seasonFilter = originalFactoryTireSeason !== ALL ? originalFactoryTireSeason : undefined;
  const query = convertDHTireTechnicalDesignationToSearchQuery(tire.technicalDesignation);
  const searchParamsBase64 = query ? toSearchParams(query, season, allBrandsId).searchParamsBase64 : '';
  const getLabel = (label: string | undefined | null) => {
    if (!label || label === 'null') return '';
    return label;
  };

  const renderAxleIcon = () => {
    switch (tire.axle) {
      case 'R':
        return <Icon IconComponent={TiresRearAxleIcon} size={28} mt={15} ml={15} mr={70} />;
      case 'F':
        return <Icon IconComponent={TiresFrontAxleIcon} size={28} mt={15} ml={15} mr={70} />;
      case 'I':
        return <Icon IconComponent={TiresBothAxlesIcon} size={28} mt={15} ml={15} mr={70} />;
      default:
        return <></>;
    }
  };

  const infoData = [
    {
      title: <Trans i18nKey="catalog.vehicle.tires.original_factory_tires.brand">Brand:</Trans>,
      data: getLabel(tire?.brand),
    },
    {
      title: <Trans i18nKey="catalog.vehicle.tires.original_factory_tires.product_line">Product line:</Trans>,
      data: getLabel(tire?.productLine),
    },
    {
      title: (
        <Trans i18nKey="catalog.vehicle.tires.original_factory_tires.technical_designation">
          Technical designation:
        </Trans>
      ),
      data: getLabel(tire?.technicalDesignation),
    },
    {
      title: <Trans i18nKey="catalog.vehicle.tires.original_factory_tires.season">Season:</Trans>,
      data: getLabel(tire?.season),
    },
  ];

  return (
    <TireReferencesPopup
      id={tire.partNumber}
      searchParamsBase64={searchParamsBase64}
      handleSearch={() => query && dispatch(tireProductsSearchRequestSaga({ query, season, brands: allBrandsId }))}
      isSelected={isSelected}
      setSelectedTire={setSelectedTire}
      marginTop={300}
      brandFilter={brandFilter}
      seasonFilter={seasonFilter}
    >
      <Flex direction={'column'} maxWidth={590} justify={'flex-start'}>
        <SCard selected={isSelected}>
          <Flex minHeight={300}>
            <Flex direction={'column'}>
              <Flex>
                {renderAxleIcon()}
                <Flex align={'center'}>
                  <Image src={'tires/tire.jpg'} height={110} width={90} alt={'tire-icon'} />
                </Flex>
              </Flex>
              {infoData.map((i, k) => (
                <InfoLine key={k} title={i.title} data={i.data} />
              ))}
              <MarginBox mb={15} />
            </Flex>
            <MarginBox mr={30} />
            <Flex direction={'column'} align={'flex-end'} justify={'center'}>
              <ImageWithLoader
                alt={'energy-label-image'}
                imageUrl={tire.energyImageUrl}
                fallbackComponent={
                  <ErrorWithLabel
                    label={t('catalog.vehicle.tires.eprel_error', 'Eprel image temporarily unavailable.')}
                  />
                }
                imageHeight={270}
                imageWidth={185}
                cursor={'pointer'}
              />
            </Flex>
          </Flex>
        </SCard>
      </Flex>
    </TireReferencesPopup>
  );
};

export default TireCard;
