/* eslint-disable max-len */
import {
  DealerType,
  Information,
  TargetPage,
} from '@1po/1po-bff-fe-spec/generated/backoffice/information/model/Information';
import { InformationBannersResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/information/response/InformationBannersResponse';
import { InformationBannerUpdate } from '@1po/1po-bff-fe-spec/generated/backoffice/information/response/InformationBannerUpdate';
import { InformationResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/information/response/InformationResponse';
import { InformationsResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/information/response/InformationsResponse';
import { OperationInformationResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/information/response/OperationInformationResponse';
import { OperationInformationTemplateResponse } from '@1po/1po-bff-fe-spec/generated/backoffice/information/response/OperationInformationTemplateResponse';
import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { ROUTER_BACKOFFICE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import {
  sendArchiveInformationRequest,
  sendGetInformationBannersRequest,
  sendGetInformationRequest,
  sendGetInformationsRequest,
  sendPublishInformationRequest,
  sendSaveInformationRequest,
  sendSaveInformationTemplateRequest,
} from 'domains/information/Information.api';
import { toInformationMessage, toInformationTemplateMessage } from 'domains/information/Information.mapper';
import * as actions from 'domains/information/Information.store';
import {
  addCreatedBanner,
  addCreatedInformation,
  archiveInformation,
  findStatusTypeByPromotionID,
  getCreateInformation,
  getCreateInformationTemplate,
  initialCreateInformation,
  removeDashboardInformation,
  resetLoadedInformations,
  setCreateInformation,
  setInformation,
  setInformationBanners,
  setInformationStatus,
  setLoadedInformations,
  setLoadedInformationsSearchStatus,
  setPublishedInformationStatus,
  updateDashboardInformation,
  updateInformationBanner,
} from 'domains/information/Information.store';
import { getDealerType, getUserContext } from 'domains/user';
import { INFORMATION } from 'pages/BackOfficePage/BackOfficePage.type';
import { notifyTop, notifyTopWithLink } from 'UI/Notification/notification';
import { AppTranslation, LOADING, sagaGuard, select } from 'utils';

function* fetchInformationRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchInformationRequestSaga>): SagaIterator {
  if (!payload.informationId) {
    return;
  }
  yield put(setInformationStatus({ id: payload.informationId, status: LOADING }));
  yield put(sendGetInformationRequest(payload));
}

function* handleInformationResponseSaga(action: WsResponse<InformationResponse>): SagaIterator {
  // TODO Check this condition
  if ('created' in action.payload) {
    return;
  }

  const informationWrapper = action.payload;
  yield put(setInformation(informationWrapper));

  const statusType = yield* select((state: RootState) =>
    findStatusTypeByPromotionID(state, informationWrapper.information.informationId),
  );
  if (!statusType) {
    return;
  }
  yield put(updateDashboardInformation({ status: statusType, information: informationWrapper.information }));
  if (statusType !== informationWrapper.information.status) {
    yield put(resetLoadedInformations(informationWrapper.information.status ?? 'SAVED'));
    yield put(
      removeDashboardInformation({ status: statusType, informationId: informationWrapper.information.informationId }),
    );
  }
}

function* fetchInformationsRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchInformationsRequestSaga>): SagaIterator {
  yield put(setLoadedInformationsSearchStatus({ statusType: payload.status, searchStatus: LOADING }));
  yield put(sendGetInformationsRequest(payload));
}

function* handleInformationsResponseSaga(action: WsResponse<InformationsResponse>): SagaIterator {
  for (const information of action.payload.informations) {
    yield put(actions.fetchInformationRequestSaga({ informationId: information.informationId }));
  }

  yield put(setLoadedInformations(action.payload));
}

function* fetchSaveInformationRequestSaga(): SagaIterator {
  const informationToSave = yield* select(getCreateInformation);
  const information = toInformationMessage(informationToSave);
  yield put(sendSaveInformationRequest(information));
}

function* handleSaveInformationResponseSaga(action: WsResponse<OperationInformationResponse>): SagaIterator {
  const { payload } = action;
  if (!payload.successful) {
    yield call(
      notifyTop,
      'error',
      AppTranslation.t('backoffice.information.save.error', 'The information has not been saved.'),
      null,
    );
    return;
  }

  yield call(
    notifyTop,
    'success',
    AppTranslation.t('backoffice.information.save.success', 'The information has been saved'),
    null,
  );

  const statusType = yield* select((state: RootState) =>
    findStatusTypeByPromotionID(state, action.payload.information.informationId),
  );
  yield put(resetLoadedInformations(statusType !== undefined ? statusType : 'SAVED'));
}

function* fetchPublishInformationRequestSaga(): SagaIterator {
  const informationToPublish = yield* select(getCreateInformation);
  const information = toInformationMessage(informationToPublish);
  yield put(sendPublishInformationRequest(information));
}

function* handlePublishInformationResponseSaga(action: WsResponse<OperationInformationResponse>): SagaIterator {
  const { successful, information, isEdited } = action.payload;
  yield put(setPublishedInformationStatus({ id: information.informationId, status: successful }));
  const dealerType = yield* select(getDealerType);

  const shouldDisplayInformation = (info: Information, dt: DealerType) => info.targetAudience.find((a) => a === dt);

  if (!successful) {
    yield call(
      notifyTop,
      'error',
      AppTranslation.t('backoffice.information.publish.error', 'The publication of information was not successful.'),
      null,
    );
    return;
  }

  if (isEdited) {
    yield call(
      notifyTop,
      'success',
      AppTranslation.t(
        'backoffice.information.edit.success',
        'Your modifications have been taken into account and are now online',
      ),
      null,
    );
  } else {
    yield call(
      notifyTop,
      'success',
      AppTranslation.t(
        'backoffice.information.publish.success',
        'The publication of your information has been taken into account and will be displayed online on Rpartsore',
      ),
      null,
    );
  }

  yield put(addCreatedInformation({ information }));
  yield put(setCreateInformation(initialCreateInformation()));
  if (information.status !== 'PUBLISHED') {
    return;
  }
  const userContext = yield* select(getUserContext);
  if (!information.targetCountries?.some((country) => country === userContext.userCountry)) {
    return;
  }
  if (new Date() < new Date(information.publicationStart) || new Date() > new Date(information.publicationEnd)) {
    return;
  }
  const textSection = information.textSections.find(
    (section) => section.language?.toUpperCase() === userContext.webLanguage.toUpperCase(),
  );
  if (!textSection) {
    return;
  }

  if (dealerType && shouldDisplayInformation(information, dealerType)) {
    const banner = {
      informationId: information.informationId,
      title: textSection.title ?? '',
      description: textSection.description ?? '',
      targetPages: information.targetPages as TargetPage[],
    };
    yield put(addCreatedBanner({ banner }));
  }
}

function* fetchInformationBannersRequestSaga(): SagaIterator {
  const userContext = yield* select(getUserContext);
  yield put(sendGetInformationBannersRequest({ userContext }));
}

function* handleInformationBannersResponseSaga(action: WsResponse<InformationBannersResponse>): SagaIterator {
  yield put(setInformationBanners(action.payload));
}

function* handleInformationBannerUpdateSaga(action: WsResponse<InformationBannerUpdate>): SagaIterator {
  yield put(updateInformationBanner(action.payload));
}

function* fetchArchiveInformationRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchArchiveInformationRequestSaga>): SagaIterator {
  yield put(sendArchiveInformationRequest(payload));
}

function* fetchArchiveInformationResponseSaga(action: WsResponse<OperationInformationResponse>): SagaIterator {
  const { information } = action.payload;
  yield put(archiveInformation(information));
}

function* fetchSaveInformationTemplateRequestSaga(): SagaIterator {
  const informationTemplateToSave = yield* select(getCreateInformationTemplate);
  const informationTemplate = toInformationTemplateMessage(informationTemplateToSave);
  yield put(sendSaveInformationTemplateRequest(informationTemplate));
}

function* handleSaveInformationTemplateResponseSaga(
  action: WsResponse<OperationInformationTemplateResponse>,
): SagaIterator {
  const { payload } = action;

  if (!payload.successful) {
    yield call(
      notifyTop,
      'error',
      AppTranslation.t(
        'backoffice.information.create_information_template.error',
        'The information template has not been saved.',
      ),
      null,
    );
    return;
  }

  yield call(
    notifyTopWithLink,
    'success',
    AppTranslation.t(
      'backoffice.information.create_information_template.save.success',
      'Your announce was successfully stored in the templates section',
    ),
    null,
    AppTranslation.t('backoffice.information.create_information_template.see', 'See'),
    `${ROUTER_BACKOFFICE}/${INFORMATION}`,
  );
}

export function* InformationSagas(): SagaIterator {
  yield takeEvery(actions.fetchInformationRequestSaga.type, sagaGuard(fetchInformationRequestSaga));
  yield takeEvery(actions.handleInformationResponseSaga.type, sagaGuard(handleInformationResponseSaga));
  yield takeEvery(actions.fetchInformationsRequestSaga.type, sagaGuard(fetchInformationsRequestSaga));
  yield takeEvery(actions.handleInformationsResponseSaga.type, sagaGuard(handleInformationsResponseSaga));
  yield takeEvery(actions.fetchSaveInformationRequestSaga.type, sagaGuard(fetchSaveInformationRequestSaga));
  yield takeEvery(actions.handleSaveInformationResponseSaga.type, sagaGuard(handleSaveInformationResponseSaga));
  yield takeEvery(actions.fetchPublishInformationRequestSaga.type, sagaGuard(fetchPublishInformationRequestSaga));
  yield takeEvery(actions.handlePublishInformationResponseSaga.type, sagaGuard(handlePublishInformationResponseSaga));
  yield takeEvery(actions.fetchInformationBannersRequestSaga.type, sagaGuard(fetchInformationBannersRequestSaga));
  yield takeEvery(actions.handleInformationBannersResponseSaga.type, sagaGuard(handleInformationBannersResponseSaga));
  yield takeEvery(actions.handleInformationBannerUpdateSaga.type, sagaGuard(handleInformationBannerUpdateSaga));
  yield takeEvery(actions.fetchArchiveInformationRequestSaga.type, sagaGuard(fetchArchiveInformationRequestSaga));
  yield takeEvery(actions.fetchArchiveInformationResponseSaga.type, sagaGuard(fetchArchiveInformationResponseSaga));
  yield takeEvery(
    actions.fetchSaveInformationTemplateRequestSaga.type,
    sagaGuard(fetchSaveInformationTemplateRequestSaga),
  );
  yield takeEvery(
    actions.handleSaveInformationTemplateResponseSaga.type,
    sagaGuard(handleSaveInformationTemplateResponseSaga),
  );
}
