import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { isEmpty } from 'fast-glob/out/utils/string';
import { TFunction } from 'i18next';
import moment from 'moment';
import { ROUTER_ESTIMATE } from 'app/AppRouter';
import StopPropagation from 'components/StopPropagation/StopPropagation';
import { fetchVehicleFunction } from 'domains/catalog/Catalog.requests';
import { getLastSearchedVehicleKey } from 'domains/catalog/Catalog.store';
import { useFetchEstimateHistory } from 'domains/estimate/Estimate.requests';
import {
  cancelEstimateDeletion,
  deleteEstimateRequest,
  fetchEstimateHistoryRequest,
  getEstimateHistorySearch,
  getEstimateHistorySearchStatus,
  resetEstimateHistory,
  setCurrentEstimateId,
  setEstimateHistorySearch,
} from 'domains/estimate/Estimate.store';
import { CenterFlex, InfiniteScroll, MarginBox, NotificationLink, notifyTop, TrashAltButton } from 'UI';
import { isLoading, textFormatter } from 'utils';
import { SHistoryTab, SSearchInput, STable } from './EstimatePage.styled';

const historyTableColumns = (t: TFunction, deleteEstimate: (estimateId: string) => void) => [
  {
    dataIndex: 'estimateId',
    key: 'deleteEstimate',
    render: function renderTrashIcon(estimateId: string) {
      return (
        <StopPropagation>
          <TrashAltButton size={16} onClick={() => deleteEstimate(estimateId)} hoverColor={'error'} />
        </StopPropagation>
      );
    },
  },
  {
    title: t('estimate.estimate_number', 'Estimate number'),
    dataIndex: 'sequenceNumber',
    key: 'estimateNumber',
  },
  {
    title: t('common.creation_date', 'Creation date'),
    dataIndex: 'creationDate',
    key: 'creationDate',
    render: function renderCreationDate(creationDate: string) {
      const date = new Date(creationDate);
      if (!moment(date).isValid()) return;
      return <CenterFlex>{textFormatter.formatDate(date)}</CenterFlex>;
    },
  },
  {
    title: t('common.creator_name', 'Estimated By'),
    dataIndex: 'creatorName',
    key: 'creator',
    render: function renderCreatorName(creatorName: string) {
      return <CenterFlex>{`${creatorName ?? ''}`}</CenterFlex>;
    },
  },
  {
    title: t('common.client_name', 'Client name'),
    dataIndex: 'clientName',
    key: 'client',
    render: function renderClientName(clientName: string | undefined) {
      return <CenterFlex>{clientName}</CenterFlex>;
    },
  },
  {
    title: t('catalog.vehicle.brand_model', 'Brand/Model'),
    dataIndex: 'vehicle',
    key: 'brandModel',
    render: function renderBrandModel(vehicle?: VehicleDetail) {
      if (!vehicle) {
        return <CenterFlex>-</CenterFlex>;
      }
      return <CenterFlex>{vehicle.vehicleBrand + ' ' + vehicle.model ?? ''}</CenterFlex>;
    },
  },
  {
    title: t('catalog.vehicle.vin_or_vrn', 'VIN or VRN'),
    dataIndex: 'vehicle',
    key: 'vinOrVrn',
    render: function renderVinOrVrn(vehicle?: VehicleDetail) {
      if (!vehicle || (!vehicle.vrn && !vehicle.vin)) {
        return <CenterFlex>-</CenterFlex>;
      }
      return <CenterFlex>{vehicle.vrn && vehicle.vrn?.length > 0 ? vehicle.vrn : vehicle.vin}</CenterFlex>;
    },
  },
];

const HistoryTab = ({ switchToEstimateTab }: { switchToEstimateTab: () => void }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const estimateHistorySearchStatus = useSelector(getEstimateHistorySearchStatus);
  const catalogVehicleKey = useSelector(getLastSearchedVehicleKey);
  const search = useSelector(getEstimateHistorySearch);
  const [estimateIdBeingDeleted, setEstimateIdBeingDeleted] = useState<string | undefined>(undefined);
  const { loadMore, hasMore, currentData } = useFetchEstimateHistory();

  useEffect(() => {
    if (estimateIdBeingDeleted) {
      notifyTop(
        'info',
        <Trans i18nKey={'estimate.history.cancel'}>{'The estimate has been removed from the list.'}</Trans>,
        undefined,
        <NotificationLink
          onClick={() => {
            dispatch(cancelEstimateDeletion({ estimateId: estimateIdBeingDeleted }));
          }}
        >
          {t('estimate.history.action.cancel', 'Cancel')}
        </NotificationLink>,
      );
      setEstimateIdBeingDeleted(undefined);
    }
    /* eslint-disable-next-line */
  }, [estimateIdBeingDeleted]);

  function onChangeDebounced(val: string) {
    const trimmedVal = val.trim();
    if (trimmedVal.length > 2 || isEmpty(trimmedVal)) {
      const value = isEmpty(trimmedVal) ? undefined : trimmedVal;
      dispatch(setEstimateHistorySearch(value));
      dispatch(resetEstimateHistory());
      dispatch(fetchEstimateHistoryRequest());
    }
  }

  const deleteEstimate = (estimateId: string) => {
    setEstimateIdBeingDeleted(estimateId);
    dispatch(deleteEstimateRequest({ estimateId }));
  };

  const dataColumns = historyTableColumns(t, deleteEstimate);

  return (
    <SHistoryTab>
      <SSearchInput
        onChangeDebounced={onChangeDebounced}
        isLoading={isLoading(estimateHistorySearchStatus)}
        placeholder={t(
          'estimate.history.search_by_vin_vrn_client_name_estimate_number',
          'Search by VRN, VIN, client name or estimate number',
        )}
        initialValue={search}
      />
      <MarginBox mt={40} />
      <InfiniteScroll loadMore={loadMore} hasMore={hasMore}>
        <STable
          columns={dataColumns}
          dataSource={currentData}
          pagination={false}
          rowKey={(row) => row.estimateId}
          loading={isLoading(estimateHistorySearchStatus)}
          onRow={(record) => {
            return {
              onClick: () => {
                if (record.vehicle !== undefined && catalogVehicleKey !== record.vehicle.vehicleKey) {
                  fetchVehicleFunction(
                    dispatch,
                    record.vehicle.vehicleKey,
                    false,
                    record.vehicle.country,
                    record.vehicle.catalogSource,
                    undefined,
                    record.vehicle.iamVehicle?.versionCode,
                  );
                }

                dispatch(setCurrentEstimateId(record.estimateId));
                history.push(`${ROUTER_ESTIMATE}`);
                switchToEstimateTab();
              },
            };
          }}
        />
      </InfiniteScroll>
    </SHistoryTab>
  );
};

export default HistoryTab;
