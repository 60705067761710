import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Season } from '@1po/1po-bff-fe-spec/generated/tire/model/Season';
import { TireSeasonSelectionProps } from 'components/TireSeasonSelection';
import { getTireSearchParams } from 'domains/tires/Tire.store';
import { ALL } from 'domains/tires/Tire.types';
import { getFavoriteBrandPreferences } from 'domains/user';
import { TireBrandSelectionProps } from 'pages/TiresPage/TireSearch/TireBrandSelection';
import { TireDimensionsSearchBar } from 'pages/TiresPage/TireSearch/TireDimensionsSearchBar';
import { TiresSearchbar } from 'pages/TiresPage/TireSearch/TiresSearchbar';
import { tireBrandsData, TireBrandType } from 'pages/TiresPage/TireSearch/TiresSearchbar/TireBrandsData';
import { Box, Flex, MarginBox, Text } from 'UI';
import { useLarge } from 'utils';

export type TireSearchProps = Omit<TireSeasonSelectionProps, 'menuOpen' | 'setMenuOpen'> & TireBrandSelectionProps;

const TireSearch = () => {
  const { t } = useTranslation();
  const large = useLarge();
  const searchParamsBase64 = useSelector(getTireSearchParams);
  const favoriteBrandsList = useSelector(getFavoriteBrandPreferences);
  const favoriteBrands = useMemo(() => {
    return tireBrandsData.map((brand) => {
      if (favoriteBrandsList.includes(brand.brand)) {
        return { ...brand, selected: true };
      }
      return brand;
    });
  }, [favoriteBrandsList]);
  const [brands, setBrands] = useState<TireBrandType[]>(favoriteBrands);
  const [season, setSeason] = useState<Season | undefined>(ALL);

  return (
    <Flex direction={large ? 'row' : 'column'} justify={'center'} align={'center'}>
      {searchParamsBase64 ? (
        <TireDimensionsSearchBar
          brands={brands}
          setBrands={(newBrands) => setBrands(newBrands)}
          season={season}
          setSeason={(newSeason) => setSeason(newSeason)}
        />
      ) : (
        <Box>
          <MarginBox mt={large ? 0 : 60}>
            <TiresSearchbar
              brands={brands}
              setBrands={(newBrands) => setBrands(newBrands)}
              season={season}
              setSeason={(newSeason) => setSeason(newSeason)}
            />
          </MarginBox>
        </Box>
      )}
      <MarginBox mx={large ? 5 : ''} my={!searchParamsBase64 ? (large ? 90 : 0) : ''}>
        <Text type={'h1_white'} disableGutter>
          {t('catalog.tires', 'Tires')}
        </Text>
      </MarginBox>
    </Flex>
  );
};

export default TireSearch;
