/* eslint-disable max-len */
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { RootState } from 'app/AppStore';
import { useFetchPlates } from 'domains/catalog/Catalog.requests';
import {
  getDetailOfLevels3,
  getExplodedTreeItem,
  getExplodedTreeLevel,
  getLastSearchedVehicleKey,
  getPlates,
  getPlatesImagesKeys,
  getSearchVehicleResult,
  plateHasDescription,
} from 'domains/catalog/Catalog.store';
import {
  PARAM_MULTIPLATE,
  PARAM_STANDARD,
  PlateIdParam,
  PlateImage,
  PlateViewModeParam,
} from 'domains/catalog/Catalog.types';
import { Box, Grid } from 'UI';
import { getData } from 'utils';
import { useBreakpointSelectorFull } from 'utils/hooks/useBreakpoint';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import Subcategory from './Subcategory';
import { TabTitle } from '../CatalogPage.styled';

const SubcategoriesSection = () => {
  const history = useHistory();
  const { query, carPartGroupId, categoryId } = useParams<{
    query: string;
    carPartGroupId: string;
    categoryId: string;
  }>();
  const params = new URLSearchParams(location.search);

  const breakpointSelectorFull = useBreakpointSelectorFull();
  const vehicleKey = useSelector(getLastSearchedVehicleKey);
  const subcategories = useSelector((state: RootState) =>
    getExplodedTreeLevel(state, { query, parentIds: [carPartGroupId, categoryId] }),
  );
  const vehicleResult = useSelector((state: RootState) => getSearchVehicleResult(state, query));
  const category = getExplodedTreeItem(getData(vehicleResult?.explodedTree), [carPartGroupId, categoryId]);

  const nodeIds = useMemo(
    () => (subcategories ? subcategories.reduce((acc: string[], { nodeId }) => [...acc, nodeId], []) : []),
    [subcategories],
  );
  const collectedPlateIds = useMemo(
    () => subcategories?.reduce((acc: string[], { plateIds }) => [...acc, ...plateIds], []),
    [subcategories],
  );

  // TODO try to use selector per plateId when rendering specific Plate Card (getPlateImage(plateId))
  const plateImages = useSelector((state: RootState) =>
    getPlatesImagesKeys(state, { plateIds: collectedPlateIds, vehicleKey }),
  );
  const plates = useSelector((state: RootState) => getPlates(state, { plateIds: collectedPlateIds, vehicleKey }));

  const subcategoriesMultiplates = useSelector((state: RootState) => getDetailOfLevels3(state, { nodeIds, query }));

  useFetchPlates(subcategories, plateImages, query, vehicleResult, subcategoriesMultiplates);

  useResetScroll();

  const handleSelectSubcategory = (subcategoryId: string) => {
    history.push({
      pathname: `${location.pathname}/${subcategoryId}`,
      search: location.search,
    });
  };

  const handleSelectMultiplate = (subcategoryId: string, plateIds: string[]) => {
    const id = plateIds[0];
    const plate = plates?.find((p) => p?.plateId === id);
    if (plateIds.length === 1 && !plateHasDescription(plate?.plateDetail)) {
      params.set(PlateViewModeParam, PARAM_STANDARD);
      params.set(PlateIdParam, id);
    } else {
      params.set(PlateViewModeParam, PARAM_MULTIPLATE);
      params.set(PlateIdParam, id);
    }
    history.push(`${location.pathname}/${subcategoryId}?${params}`);
  };

  // the bottom Box is here only to enable to show bottom box-shadow of last row of items in grid, temporary workaround
  return (
    <>
      <TabTitle data-cy={'level2-title'}>{category?.label}</TabTitle>
      <Grid
        columns={breakpointSelectorFull({
          xs: 1,
          sm: 1,
          md: 1,
          lg: 2,
          xl: 3,
          xxl: 3,
        })}
        gutter={[
          [30, 30],
          [30, 30],
        ]}
      >
        {subcategories?.map(({ label, nodeId: subcategoryId, plateIds: subcategoryPlateIds }) => {
          // TODO clean up typescript
          const subcategoryPlates = subcategoryPlateIds
            .map((id) => plateImages.find((plate) => plate?.plateId === id))
            .filter((x) => x)
            .reduce((acc, next) => acc.set(next ? next.plateId : '', next), new Map<string, PlateImage | undefined>());
          if (!subcategoryPlates) {
            return null;
          }
          return (
            <Subcategory
              key={subcategoryId}
              query={query}
              carPartGroupId={carPartGroupId}
              categoryId={categoryId}
              subcategoryId={subcategoryId}
              label={label}
              plates={subcategoryPlates}
              multiplates={subcategoriesMultiplates.get(subcategoryId)}
              onSelectSubcategory={handleSelectSubcategory}
              onSelectMultiplate={handleSelectMultiplate}
            />
          );
        })}
      </Grid>
      <Box height={15} />
    </>
  );
};
export default SubcategoriesSection;
