import React, { useState } from 'react';
import styled from 'styled-components';
import { Product } from 'pages/CatalogPage/DH/Product';
import { Modal } from 'UI';

interface ProductModalProps {
  triggerComponent: React.ReactNode;
  referenceNumber: string;
  seePlate?: React.ReactNode;
  isVehicleCatalog?: boolean;
  scrollToSubstitutes?: boolean;
  id?: string;
}

const SModal = styled(Modal)`
  .ant-modal-body {
    padding: 0 0 30px 0;
  }
`;

const ProductModal = ({
  triggerComponent,
  referenceNumber,
  seePlate,
  scrollToSubstitutes,
  isVehicleCatalog,
  id,
}: ProductModalProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleChangeShowModal = () => {
    setShowModal((prev) => !prev);
  };

  return (
    <>
      <div onClick={handleChangeShowModal} key={id}>
        {triggerComponent}
      </div>
      <SModal
        open={showModal}
        footer={''}
        onCancel={() => setShowModal(false)}
        width={'80%'}
        zIndex={1000}
        key={`modal-${id}`}
      >
        <Product
          referenceNumber={referenceNumber}
          isPopin
          seePlate={seePlate}
          scrollToSubstitutes={scrollToSubstitutes}
          isVehicleCatalog={isVehicleCatalog}
        />
      </SModal>
    </>
  );
};

export default ProductModal;
