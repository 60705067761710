import styled from 'styled-components';
import { Text } from 'UI';

export const SBackground = styled.div<{ image: string }>`
  background-image: url(${({ image }) => `/images/${image}`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 250px;
`;

export const IconButtonCube = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 140px;
  width: 160px;
  margin: 0 5px 0 5px;
`;

export const IconButtonLabel = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 160px;
  flex: none;
`;
