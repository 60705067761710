/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, useHistory, useParams } from 'react-router-dom';
import { VehiclePartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehiclePartCategoryTreeItem';
import {
  ROUTER_CATALOG_DH_L3,
  ROUTER_CATALOG_TIRES,
  ROUTER_CATALOG_VEHICLE,
  ROUTER_DETAILS,
  ROUTER_PRODUCT,
} from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { ExternalLinkIcon } from 'assets/icons';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import PageViewCounter from 'components/Help/PageViewCounter';
import Loader from 'components/Loader';
import { getTechnicalDocumentationLink } from 'domains/appContext/AppContext.store';
import {
  useFetchDHTire,
  useFetchKadensisTechnicalData,
  useFetchMaintenancePlan,
  useFetchVehicle,
  useUpdateVehicleSearchHistory,
} from 'domains/catalog/Catalog.requests';
import {
  getLastSearchedVehicleKey,
  getLastSearchVehicleTechnicalData,
  getLastSearchVehicleVersionCode,
  getSearchVehicleResult,
  resetPreviousTechnicalCriteria,
  setLastSearchedVehicleKey,
} from 'domains/catalog/Catalog.store';
import {
  DhCatalogTabsType,
  FLUIDS_ID,
  IamCatalogTabsType,
  TECHNICAL_DATA_TAB,
  TECHNICAL_DOCUMENTATION_TAB,
  TechnicalDataIdParam,
  VehicleCountry,
} from 'domains/catalog/Catalog.types';
import { getCatalogSourceUrl, getDHCatalogSourcePaths } from 'domains/catalog/Catalog.utils';
import { useFetchEstimateSettingsSubscription } from 'domains/estimate/Estimate.requests';
import { getDhCatalogTabsView, getUserRights, setDhCatalogTabsView } from 'domains/user';
import { CatalogTabs, renderVehicleTabContentSticky } from 'pages/CatalogPage/common';
import CatalogBreadcrumb from 'pages/CatalogPage/common/CatalogBreadcrumb';
import { ProductSection } from 'pages/CatalogPage/DH/Product/ProductSection';
import { Link, TabTitle } from 'pages/CatalogPage/IAM/CatalogPage.styled';
import { VehicleTechnicalData } from 'pages/CatalogPage/IAM/VehicleTechnicalData/VehicleTechnicalData';
import { theme } from 'styles';
import {
  Container,
  defaultPadding,
  Empty,
  Flex,
  Icon,
  indentPadding,
  MarginBox,
  StickyBoxCustom,
  Tab,
  Tabs,
  TabsTitle,
} from 'UI';
import { ERROR, FOUND, getData, hasUserR3WithSubscriptionL234, isQueryBMM, NO_DATA, NOT_FOUND } from 'utils';
import { useIsScrolled } from 'utils/hooks/useIsScrolled';
import { OFFSET_SUB_HEADER, useOffsetTop } from 'utils/hooks/useOffsetTop';
import CarPartGroupsSection from './CarPartGroupsSection';
import FullTextSearchResult from './FullTextSearchResult/FullTextSearchResult';
import SubcategoriesSection from './SubcategoriesSection';
import SubcategorySection from './SubcategorySection';
import Tires from './Tires';
import { VehicleTechnicalDetails } from './VehicleTechnicalDetails/VehicleTechnicalDetails';
import QuickAccess from '../common/QuickAccess';
import { useResetQuickAccessCategories } from '../common/QuickAccess/QuickAccess';

export const CatalogTabsDH = ({
  tree,
  tabKey,
  setTabKey,
}: {
  tree: VehiclePartCategoryTreeItem[] | NO_DATA;
  tabKey: IamCatalogTabsType;
  setTabKey: (t: IamCatalogTabsType) => void;
}) => {
  const { t } = useTranslation();
  const technicalDocumentationUrl = useSelector(getTechnicalDocumentationLink);
  const versionCode = useSelector((state: RootState) => getLastSearchVehicleVersionCode(state));
  const technicalDocumentationVersionUrl =
    (versionCode && technicalDocumentationUrl?.replace('{vehicleId}', versionCode)) ?? '';
  const vehicleTechnicalData = useSelector((state: RootState) => getLastSearchVehicleTechnicalData(state));
  const hasTechnicalData = vehicleTechnicalData && vehicleTechnicalData.length > 0;
  const rights = useSelector(getUserRights);
  const uparams = new URLSearchParams(location.search);
  uparams.set(TechnicalDataIdParam, FLUIDS_ID);

  const onTabChange = (tab: DhCatalogTabsType) => {
    if (tab === TECHNICAL_DOCUMENTATION_TAB) {
      return;
    }
    setTabKey(tab);
  };

  function getTechnicalDocumentationTab() {
    if (!versionCode) {
      return <></>;
    }
    if (hasUserR3WithSubscriptionL234(getData(rights))) {
      return (
        <>
          {hasTechnicalData && (
            <Tab
              tab={<TabsTitle>{t('catalog.parts.category.technical_data', 'Technical data')}</TabsTitle>}
              key={'technical_data'}
              data-cy={'tab-technical-data'}
            >
              <TabTitle>{t('catalog.parts.category.technical_data', 'Technical data')}</TabTitle>
              <VehicleTechnicalData tabKey={tabKey} />
            </Tab>
          )}
          <Tab
            tab={
              <TabsTitle>
                <Link
                  active={TECHNICAL_DOCUMENTATION_TAB === tabKey}
                  href={technicalDocumentationVersionUrl}
                  target={'_blank'}
                  rel={'noreferrer'}
                >
                  {t('catalog.parts.category.technical_documentation', 'Technical documentation')}
                  <Icon
                    ml={5}
                    size={14}
                    IconComponent={ExternalLinkIcon}
                    color={TECHNICAL_DOCUMENTATION_TAB === tabKey ? theme.color.info : theme.color.brand_black}
                  />
                </Link>
              </TabsTitle>
            }
            key={'technical_documentation'}
          >
            <TabTitle>{t('catalog.parts.category.technical_documentation', 'Technical documentation')}</TabTitle>
          </Tab>
        </>
      );
    } else {
      return <></>;
    }
  }

  return (
    <DataContainer
      data={tree}
      Error={() => (
        <ErrorWithLabel
          label={t('catalog.parts.backend_error', 'Car parts are temporarily unavailable, please try again later.')}
        />
      )}
    >
      <Switch>
        <Route path={getDHCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}/search/:label`)} exact>
          <FullTextSearchResult />
        </Route>
        <Route
          path={getDHCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}${ROUTER_PRODUCT}/:referenceNumber`)}
          exact
        >
          <ProductSection />
        </Route>
        <Route
          path={getDHCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}/:carPartGroupId/:categoryId/:subcategoryId`)}
          exact
        >
          <Tabs activeKey={tabKey} onChange={onTabChange}>
            <Tab tab={<TabsTitle>{t('catalog.parts.category.car_parts', 'Car parts')}</TabsTitle>} key={'car_parts'}>
              <MarginBox my={15} ml={20}>
                <DataContainer data={tree} Error={() => <></>}>
                  <CatalogBreadcrumb tree={tree} />
                </DataContainer>
              </MarginBox>
              <PageViewCounter page={ROUTER_CATALOG_DH_L3} />
              <SubcategorySection />
            </Tab>
            {getTechnicalDocumentationTab()}
          </Tabs>
        </Route>
        <Route path={getDHCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}/:carPartGroupId/:categoryId`)} exact>
          <Tabs activeKey={tabKey} onChange={onTabChange}>
            <Tab tab={<TabsTitle>{t('catalog.parts.category.car_parts', 'Car parts')}</TabsTitle>} key={'car_parts'}>
              <MarginBox my={15} ml={20}>
                <DataContainer data={tree} Error={() => <></>}>
                  <CatalogBreadcrumb tree={tree} />
                </DataContainer>
              </MarginBox>
              <SubcategoriesSection />
            </Tab>
            {getTechnicalDocumentationTab()}
          </Tabs>
        </Route>
        <Route path={getDHCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}/:carPartGroupId?`)} exact>
          <Tabs activeKey={tabKey} onChange={onTabChange}>
            <Tab tab={<TabsTitle>{t('catalog.parts.category.car_parts', 'Car parts')}</TabsTitle>} key={'car_parts'}>
              <MarginBox my={15} ml={20}>
                <DataContainer data={tree} Error={() => <></>}>
                  <CatalogBreadcrumb tree={tree} />
                </DataContainer>
              </MarginBox>
              {/*todo enable when catalog l1 first help*/}
              {/*<PageViewCounter page={ROUTER_CATALOG_DH_L1} />*/}
              <CarPartGroupsSection />
            </Tab>
            {getTechnicalDocumentationTab()}
          </Tabs>
        </Route>
      </Switch>
    </DataContainer>
  );
};

const CatalogPage = ({ setCatalogShowMenu }: { setCatalogShowMenu: (b: boolean) => void }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { query } = useParams<{
    query: string;
  }>();
  const [isChangeVehicle, setIsChangeVehicle] = useState(false);
  const result = useSelector((state: RootState) => getSearchVehicleResult(state, query));
  const tree = result?.explodedTree;
  const searchStatus = result?.searchVehicleStatus;
  const lastSearchedVehicleKey = useSelector(getLastSearchedVehicleKey);
  const lastSearchedResult = useSelector((state: RootState) => getSearchVehicleResult(state, lastSearchedVehicleKey));
  const params = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const tabKey = useSelector(getDhCatalogTabsView);
  const offsetTop = useOffsetTop('RENAULT_CATALOG');
  const isBMM = isQueryBMM(result?.vehicleDetail?.vehicleKey ?? '');
  const isScrolled = useIsScrolled();

  useEffect(() => {
    if (lastSearchedVehicleKey !== query) {
      dispatch(resetPreviousTechnicalCriteria());
      dispatch(setLastSearchedVehicleKey(query));
    }
  }, [dispatch, query, lastSearchedVehicleKey]);

  useEffect(() => {
    if (isChangeVehicle) {
      if (
        lastSearchedVehicleKey !== query &&
        lastSearchedResult?.searchVehicleStatus === FOUND &&
        lastSearchedResult?.vehicleDetail
      ) {
        setIsChangeVehicle(false);
        history.push(
          `${getCatalogSourceUrl(lastSearchedResult?.vehicleDetail?.catalogSource)}/${
            lastSearchedResult?.vehicleDetail?.vehicleKey
          }${ROUTER_CATALOG_VEHICLE}?${params}`,
        );
      }
    }
    // eslint-disable-next-line
  }, [isChangeVehicle, lastSearchedVehicleKey]);

  useEffect(() => {
    if (tabKey !== TECHNICAL_DATA_TAB) {
      params.delete(TechnicalDataIdParam);
      history.replace(`${location.pathname}?${params}`);
    }
    // eslint-disable-next-line
  }, [tabKey]);

  useEffect(() => {
    const country = result?.vehicleDetail?.country;
    if (params.get(VehicleCountry) !== country && country) {
      params.set(VehicleCountry, country);
      history.replace(`${location.pathname}?${params}`);
    }
  });

  useFetchEstimateSettingsSubscription();
  useFetchVehicle(query, searchStatus);
  useFetchDHTire(result);
  useUpdateVehicleSearchHistory(result?.vehicleDetail);
  useFetchMaintenancePlan();
  useResetQuickAccessCategories();
  useFetchKadensisTechnicalData();

  if (!result || !result.vehicleDetail) {
    switch (searchStatus) {
      case NOT_FOUND:
        return (
          <Container padding={defaultPadding}>
            <MarginBox my={220}>
              <Empty />
            </MarginBox>
          </Container>
        );
      case ERROR:
        return (
          <Container padding={defaultPadding}>
            <MarginBox my={220}>
              <ErrorWithLabel
                label={t(
                  'catalog.parts.search.by_vin_or_vrn.error',
                  'Sorry, we could not find any vehicle associated, please try again later.',
                )}
              />
            </MarginBox>
          </Container>
        );
      default:
        return <Loader />;
    }
  }

  const setTabKey = (tab: DhCatalogTabsType) => {
    dispatch(setDhCatalogTabsView(tab));
  };

  return (
    <Container padding={indentPadding} alignText={'left'} height={'100%'} justify={'flex-start'}>
      <StickyBoxCustom offsetTop={offsetTop + OFFSET_SUB_HEADER} zIndex={100}>
        <CatalogTabs
          result={result.vehicleDetail}
          vehicleImage={result?.vehicleDetail?.imageUrl}
          query={query}
          setCatalogShowMenu={() => undefined}
          setCatalogTabKey={setTabKey}
          setIsChangeVehicle={setIsChangeVehicle}
        />
      </StickyBoxCustom>
      <Switch>
        <Route path={getDHCatalogSourcePaths(`/:query${ROUTER_DETAILS}`)} exact>
          <VehicleTechnicalDetails />
        </Route>
        <Route path={getDHCatalogSourcePaths(`/:query${ROUTER_CATALOG_TIRES}`)}>
          {renderVehicleTabContentSticky(
            offsetTop,
            result.vehicleDetail,
            result?.vehicleDetail?.imageUrl,
            isBMM,
            query,
            isScrolled,
            setIsChangeVehicle,
          )}
          <QuickAccess />
          <Tires setCatalogShowMenu={setCatalogShowMenu} />
        </Route>
        <Route path={getDHCatalogSourcePaths(`/:query/search/:label`)}>
          <CatalogTabsDH tree={tree} tabKey={tabKey} setTabKey={setTabKey} />
        </Route>
        <Route
          path={getDHCatalogSourcePaths(
            `/:query${ROUTER_CATALOG_VEHICLE}/:carPartGroupId?/:categoryId?/:subcategoryId?/:plateId?`,
          )}
        >
          <QuickAccess />
          <CatalogTabsDH tree={tree} tabKey={tabKey} setTabKey={setTabKey} />
          <Flex />
        </Route>
        <Route path={getDHCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}${ROUTER_PRODUCT}`)}>
          <CatalogTabsDH tree={tree} tabKey={tabKey} setTabKey={setTabKey} />
        </Route>
      </Switch>
    </Container>
  );
};

export default CatalogPage;
