import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown } from 'antd';
import { DefaultBackendError } from 'components/DataContainer';
import { SItem, SMenu } from 'pages/TiresPage/TireSearch/TireDimensionsSearchBar/TireDimensionsSearchBar.styled';
import { theme } from 'styles';
import { Box, CenterFlex, DropDownButton, Flex, MarginBox, Text } from 'UI';

interface TireCriteriaItemProps {
  label?: string;
  initialValue?: string;
  onValueChange?: (newValue?: string) => void;
  items: string[];
}

export const TireDimensionsMenuItem = ({ label, initialValue, onValueChange, items }: TireCriteriaItemProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string | undefined>(initialValue);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    onValueChange && onValueChange(value);
    //eslint-disable-next-line
  }, [value]);

  const menu = (
    <SMenu direction={'column'} maxWidth={520}>
      <Flex direction={'row'}>
        <Flex justify={'flex-start'}>
          <Text type={'lead'}>{label}</Text>
        </Flex>
        {onValueChange && (
          <Flex justify={'flex-end'}>
            <Text
              type={'light_dimmer'}
              cursor={'pointer'}
              onClick={() => {
                setOpen(false);
                setValue(undefined);
              }}
              hoverUnderLine
            >
              {t('catalog.tires.search.clear_selection', 'Clear selection')}
            </Text>
          </Flex>
        )}
      </Flex>
      <MarginBox mt={10} />
      <Flex direction={'row'} wrap={'wrap'} align={'center'} background={theme.color.white}>
        {items ? (
          <>
            {items?.map((item) => {
              return (
                <SItem
                  key={item}
                  onClick={() => {
                    setValue(item);
                    setOpen(false);
                  }}
                  active={item === value}
                >
                  <Box width={30} align={'center'}>
                    <CenterFlex>
                      <Text type={'text'}>{item}</Text>
                    </CenterFlex>
                  </Box>
                </SItem>
              );
            })}
          </>
        ) : (
          <Text type={'text'}>{DefaultBackendError}</Text>
        )}
      </Flex>
    </SMenu>
  );

  return (
    <Box width={200}>
      <Dropdown overlay={menu} trigger={['click']} visible={open} onVisibleChange={(visible) => setOpen(visible)}>
        <a onClick={(e) => e.preventDefault()}>
          <DropDownButton stretch>
            <Box width={155} align={'left'}>
              <Text type={'lead_dim'} ellipsis>
                {value ? value : `Select ${label}`}
              </Text>
            </Box>
          </DropDownButton>
        </a>
      </Dropdown>
    </Box>
  );
};
