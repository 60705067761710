import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { OrderItem, VehicleDetail } from '@1po/1po-bff-fe-spec/generated/order/response/GetDealerOrderPageResponse';
import { RootState } from 'app/AppStore';
import { CloneIcon, ReplyIcon } from 'assets/icons';
import { CopyToClipboardButton } from 'components/CopyToClipboardButton';
import { DataContainer } from 'components/DataContainer';
import { QuantityBox } from 'components/QuantityBox/QuantityBox';
import { OrderStockDisplay } from 'components/StockInfo/StockDisplay';
import { useFetchSingleReference } from 'domains/catalog/Catalog.requests';
import { updateReturnRequest } from 'domains/order/Order.store';
import { OrderReferenceItemLocal, SubstituteReferenceLocal } from 'domains/order/Order.types';
import { getDHReference, getIAMReference } from 'domains/references';
import { isRefMKTP } from 'domains/references/References.utils';
import {
  DeliveredQuantity,
  getImageUrl,
  HeadlineSubstitute,
  OrderReferenceImage,
  renderSubstituteFooter,
  ReplacementReference,
} from 'pages/MyOrdersPage/OrderReferencesSection/OrderReferenceCard';
import { SubstituteProducts } from 'pages/MyOrdersPage/OrderReferencesSection/OrderReferenceCard.styled';
import RequestReasonSelect from 'pages/MyOrdersPage/OrderReturnSection/RequestReasonSelect';
import { theme } from 'styles';
import { Box, Flex, Icon, MarginBox, Pipeline, Spin, Text } from 'UI';
import { getData } from 'utils';

const OrderReferenceReturnCard = ({
  reference,
  index,
  order,
  substituteInlineInfo,
}: {
  reference: OrderReferenceItemLocal;
  index: number;
  order: OrderItem;
  substituteInlineInfo?: boolean;
}) => {
  const { t } = useTranslation();
  const singleLineStock = reference.warehouseDeliveryStatuses && reference.warehouseDeliveryStatuses.length === 1;
  const dispatch = useDispatch();
  const enableReturn = reference.status === 'DELIVERED' || reference.status === 'PARTIALLY_DELIVERED';
  const alreadyReturnedQty = reference.returnedQuantity ?? 0;
  const maximumReturnQty =
    (reference.deliveredQuantity ?? 0) - alreadyReturnedQty - (reference.deliveredSubstitutedQuantity ?? 0);

  const referenceNumber = reference.referenceNumber;
  const IAMReference = useSelector((state: RootState) =>
    getIAMReference(state, { referenceNumber, vehicleKey: undefined }),
  );
  useFetchSingleReference(referenceNumber, null, undefined);
  const catalogReference = useSelector((state: RootState) =>
    getDHReference(state, { referenceNumber, vehicleKey: undefined }),
  );
  const isMKTP = isRefMKTP(getData(IAMReference));

  const updateReturn = (substitutedIndex?: number) => (
    updatedReference: OrderReferenceItemLocal | SubstituteReferenceLocal,
  ) => {
    if (substitutedIndex === undefined) {
      dispatch(
        updateReturnRequest({
          index,
          orderId: order.internalOrderId,
          reference: updatedReference as OrderReferenceItemLocal,
        }),
      );
      return;
    }

    if (!reference.substitutes) {
      return;
    }
    const substitutesCopy = [...reference.substitutes];
    substitutesCopy[substitutedIndex] = updatedReference as SubstituteReferenceLocal;
    const updatedReferenceWithSubstitute = {
      ...reference,
      substitutes: substitutesCopy,
    };

    dispatch(
      updateReturnRequest({
        index,
        orderId: order.internalOrderId,
        reference: updatedReferenceWithSubstitute as OrderReferenceItemLocal,
      }),
    );
  };

  const renderStocks = () => {
    if (!('orderDate' in order)) {
      return <></>;
    }
    return (
      <OrderStockDisplay
        orderId={order.internalOrderId}
        referenceNumber={reference.referenceNumber}
        warehouseDeliveryStatuses={reference.warehouseDeliveryStatuses}
        narrow={false}
        quantity={Number(reference.quantity)}
        orderDate={order.orderDate}
        isMKTP={isMKTP}
      />
    );
  };

  return (
    <>
      {substituteInlineInfo !== undefined && !substituteInlineInfo && <HeadlineSubstitute reference={reference} />}
      <MarginBox mb={15}>
        <Flex>
          <Box width={100} height={100}>
            <OrderReferenceImage
              referenceImageUrl={getImageUrl(getData(catalogReference))}
              referenceBrand={reference.brand}
              vehicleDetail={'vehicleDetail' in order ? order.vehicleDetail : undefined}
            />
          </Box>
          <MarginBox ml={15} />
          <Flex direction={'column'}>
            <Flex direction={'row'} align={'center'}>
              <Text type={'text_bold'}>
                {t('catalog.reference.referenceNumber.short', 'Ref: {{referenceNumber}}', {
                  referenceNumber: reference.referenceNumber,
                })}
              </Text>
              <MarginBox mr={10} />
              <CopyToClipboardButton
                textToCopy={reference.referenceNumber}
                message={t(
                  'catalog.reference_card.reference_number.copied_to_clipboard',
                  'Reference number {{referenceNumber}} copied to clipboard',
                  { referenceNumber: reference.referenceNumber },
                )}
              />
              {substituteInlineInfo && <ReplacementReference reference={reference} />}
            </Flex>
            <Text type={'light_14_black_65'}>{reference.name}</Text>
            {/*todo: stocks for external orders are waiting for Jamale's implementation*/}
            <Flex maxHeight={22}>
              <Text type={'text_bold'}>{t('common.stock', 'Stock: ')}</Text>
              <MarginBox ml={5} />
              {singleLineStock && renderStocks()}
            </Flex>
            {!singleLineStock && <MarginBox ml={15}>{renderStocks()}</MarginBox>}
            <Flex>
              <Text type={'text_bold'}>{t('common.quantity.short', 'Qty: ')}</Text>
              <MarginBox ml={5} />
              <Text type={'light_14_black_65'}>
                {`${reference.quantity} `}
                <DeliveredQuantity
                  deliveredQuantity={reference.deliveredQuantity}
                  deliveredSubstitutedQuantity={reference.deliveredSubstitutedQuantity}
                />
              </Text>
              {enableReturn && reference.returnedQuantity !== undefined && reference.returnedQuantity !== 0 && (
                <MarginBox ml={5}>
                  <Icon IconComponent={ReplyIcon} size={10} color={theme.color.success} mr={3} />
                  <Text type={'light_14_black_65'}>
                    {t('order.reference_table.references.return_status', 'Returned articles: {{ returnCount }}', {
                      returnCount: reference.returnedQuantity,
                    })}
                  </Text>
                </MarginBox>
              )}
            </Flex>
          </Flex>
          {reference.referenceMark && (
            <Flex direction={'column'}>
              <Text type={'text_bold'}>{t('order.referenceMark', 'Reference mark: ')}</Text>
              <Text type={'light_14_black_65'}>{reference.referenceMark}</Text>
            </Flex>
          )}
          {enableReturn && maximumReturnQty > 0 && (
            <>
              <Flex align={'center'}>
                <MarginBox mr={280} />
                <RequestReasonSelect reference={reference} updateReturn={updateReturn()} />
                <MarginBox ml={30} mt={3}>
                  <QuantityBox
                    medium
                    value={reference.qtyToReturn}
                    onChange={(quantity) => updateReturn()({ ...reference, qtyToReturn: quantity as number })}
                    maxValue={maximumReturnQty}
                  />
                </MarginBox>
              </Flex>
              <Box width={60} />
            </>
          )}
        </Flex>
        <MarginBox mt={15} />
        <Pipeline size={'100%'} horizontal color={theme.color.grey85} />
      </MarginBox>
      {substituteInlineInfo !== undefined && !substituteInlineInfo && (
        <>
          {reference.substitutes && reference.substitutes.length > 0 && (
            <SubstituteProducts>
              <Icon IconComponent={CloneIcon} color={theme.color.brand_black} size={20} mr={15} />
              <Text type={'h5_bold'}>{t('order.substitute_products', 'Substitute products')}</Text>
            </SubstituteProducts>
          )}
          {reference.substitutes?.map((s, index) => (
            <OrderReferenceReturnSubstituteCard
              key={s.referenceNumber}
              substituteReference={s}
              vehicleDetail={'vehicleDetail' in order ? order.vehicleDetail : undefined}
              updateReturn={updateReturn(index)}
              enableReturn={enableReturn}
            />
          ))}
        </>
      )}
      {renderSubstituteFooter(substituteInlineInfo, reference)}
    </>
  );
};

const OrderReferenceReturnSubstituteCard = ({
  substituteReference,
  vehicleDetail,
  updateReturn,
  enableReturn,
}: {
  substituteReference: SubstituteReferenceLocal;
  vehicleDetail: VehicleDetail | undefined;
  updateReturn: (updatedReference: OrderReferenceItemLocal | SubstituteReferenceLocal) => void;
  enableReturn: boolean;
}) => {
  const { t } = useTranslation();
  const substituteReferenceNumber = substituteReference.referenceNumber;
  const deliveredQuantity = substituteReference.deliveredQuantity;

  const alreadyReturnedQty = substituteReference.returnedQuantity ?? 0;
  const maximumReturnQty = deliveredQuantity - alreadyReturnedQty;

  const substituteRef = useSelector((state: RootState) =>
    getDHReference(state, { referenceNumber: substituteReferenceNumber, vehicleKey: undefined }),
  );
  const substituteRefData = getData(substituteRef);

  useFetchSingleReference(substituteReference.referenceNumber, null, undefined);

  return (
    <MarginBox mx={0} mb={15}>
      <Flex>
        <Box width={100} height={100}>
          <OrderReferenceImage
            referenceImageUrl={substituteRefData?.imageKeys?.[0]}
            referenceBrand={substituteRefData?.brand}
            vehicleDetail={vehicleDetail}
          />
        </Box>
        <MarginBox ml={15} />
        <Flex direction={'column'}>
          <Flex direction={'row'}>
            <Text type={'text_bold'}>
              {t('catalog.reference.referenceNumber.short', 'Ref: {{referenceNumber}}', {
                referenceNumber: substituteReference.referenceNumber,
              })}
            </Text>
            <MarginBox mr={10} />
            <CopyToClipboardButton
              textToCopy={substituteReference.referenceNumber}
              message={t(
                'catalog.reference_card.reference_number.copied_to_clipboard',
                'Reference number {{referenceNumber}} copied to clipboard',
                { referenceNumber: substituteReference.referenceNumber },
              )}
            />
          </Flex>
          <DataContainer
            data={substituteRef}
            NotFound={() => <></>}
            Error={() => <></>}
            Loading={() => <Spin size={'small'} />}
          >
            <Text type={'light_14_black_65'}>{substituteRefData?.name}</Text>
          </DataContainer>
          <Flex>
            <Text type={'text_bold'}>{t('common.quantity.short', 'Qty: ')}</Text>
            <MarginBox ml={5} />
            <Text type={'light_14_black_65'}>
              {`${deliveredQuantity} `}
              <DeliveredQuantity deliveredQuantity={deliveredQuantity} deliveredSubstitutedQuantity={undefined} />
            </Text>
            {enableReturn &&
              substituteReference.returnedQuantity !== undefined &&
              substituteReference.returnedQuantity !== 0 && (
                <MarginBox ml={5}>
                  <Icon IconComponent={ReplyIcon} size={10} color={theme.color.success} mr={3} />
                  <Text type={'light_14_black_65'}>
                    {t('order.reference_table.references.return_status', 'Returned articles: {{ returnCount }}', {
                      returnCount: substituteReference.returnedQuantity,
                    })}
                  </Text>
                </MarginBox>
              )}
          </Flex>
        </Flex>
        {enableReturn && maximumReturnQty > 0 && (
          <>
            <Flex align={'center'}>
              <MarginBox mr={280} />
              <RequestReasonSelect reference={substituteReference} updateReturn={updateReturn} />
              <MarginBox ml={30} mt={3}>
                <QuantityBox
                  medium
                  value={substituteReference.qtyToReturn}
                  onChange={(quantity) => updateReturn({ ...substituteReference, qtyToReturn: quantity as number })}
                  maxValue={maximumReturnQty}
                />
              </MarginBox>
            </Flex>
            <Box width={60} />
          </>
        )}
      </Flex>
      <MarginBox mt={15} />
      <Pipeline size={'100%'} horizontal color={theme.color.grey85} />
    </MarginBox>
  );
};

export default OrderReferenceReturnCard;
