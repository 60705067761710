import React, { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FlashPromotionBanner } from '@1po/1po-bff-fe-spec/generated/backoffice/promotion/model/FlashPromotionBanner';
import { ROUTER_PRODUCT } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { trackAppEvent } from 'app/AppTracker';
import { SAdvertisementSpace } from 'components/Page/Header/AdvertisementSpace/Banner.styled';
import LeftBannerSide from 'components/Page/Header/AdvertisementSpace/LeftBannerSide';
import RightBannerSide from 'components/Page/Header/AdvertisementSpace/RightBannerSide';
import PrivateComponent from 'composers/PrivateComponent';
import { addReferenceByRefNumberRequest, getActionStatus, getBasketOtherSection } from 'domains/basket/Basket.store';
import {
  STATUS_MISSING_PRICE,
  STATUS_NOT_IN_STOCK,
  STATUS_SUCCESS,
  STATUS_UNKNOWN_REFERENCE,
} from 'domains/basket/Basket.types';
import { useFetchSingleReference } from 'domains/catalog/Catalog.requests';
import { getDHReference, getPrice } from 'domains/references';
import { useFetchPrices } from 'domains/references/References.requests';
import { UserRole } from 'domains/user';
import { Text, YellowButton } from 'UI';
import { notifyTop } from 'UI/Notification/notification';
import { hasData } from 'utils';
import { TRACKING_EVENT_ADD_PROMOTION_TO_CART } from 'utils/eventTracker/EventTracker.types';

const notifyDetailError = (refNumber: string) => {
  notifyTop(
    'error',
    <Trans i18nKey={'common.warning'}>Warning</Trans>,
    <Trans i18nKey={'backoffice.promotion.banner.no_reference'} tOptions={{ refNumber }}>
      Reference detail for reference number: {{ refNumber }} was not found.
    </Trans>,
  );
};

const FlashQuantityLimitedBanner = ({ banner }: { banner: FlashPromotionBanner }) => {
  const referenceNumber = banner.referenceNumber;
  const [addToCartClicked, setAddToCartClicked] = useState<boolean>(false);
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const reference = useSelector((state: RootState) =>
    getDHReference(state, { referenceNumber, vehicleKey: undefined }),
  );
  const price = useSelector((state: RootState) => getPrice(state, referenceNumber));
  const referenceAddToCartAction = useSelector((state: RootState) => getActionStatus(state, referenceNumber));
  const otherSection = useSelector(getBasketOtherSection);
  const referenceInBasket = otherSection.references.find((ref) => ref.referenceNumber === referenceNumber);

  useFetchPrices(referenceNumber ? [referenceNumber] : []);
  useFetchSingleReference(referenceNumber, null, undefined);

  const notifyAddToCart = useCallback(
    (status: string) => {
      switch (status) {
        case STATUS_SUCCESS:
          setAddToCartClicked(false);
          notifyTop(
            'success',
            <Trans i18nKey={'backoffice.promotion.banner.reference_added'}>Reference added</Trans>,
            referenceInBasket?.quantity && referenceInBasket?.quantity > 1 ? (
              <Trans i18nKey={'cart.action.referenceUpdated'}>
                Your reference has been added and the quantity has been updated
              </Trans>
            ) : (
              <Trans i18nKey={'cart.action.referenceAdded'}>1 Reference has been added to your cart.</Trans>
            ),
          );
          return;
        case STATUS_MISSING_PRICE:
          setAddToCartClicked(false);
          notifyTop(
            'error',
            <Trans i18nKey={'common.warning'}>Warning</Trans>,
            <Trans i18nKey={'cart.action.add_reference.missing_price'}>
              Price currently unavailable, please try again later.
            </Trans>,
          );
          return;
        case STATUS_UNKNOWN_REFERENCE:
          setAddToCartClicked(false);
          notifyTop(
            'error',
            <Trans i18nKey={'common.warning'}>Warning</Trans>,
            <Trans i18nKey={'cart.action.add_reference.unknown_reference'}>
              Your reference has not been added to your cart, please try again.
            </Trans>,
          );
          return;
        case STATUS_NOT_IN_STOCK:
          setAddToCartClicked(false);
          notifyTop(
            'error',
            <Trans i18nKey={'common.warning'}>Warning</Trans>,
            <Trans i18nKey={'cart.action.addref_errormessage_stock'}>
              This product is not longer in stock and cannot be ordered.
            </Trans>,
          );
          return;
      }
    },
    [referenceInBasket?.quantity, setAddToCartClicked],
  );

  // todo: this needs to be specified by FR

  useEffect(() => {
    if (addToCartClicked && referenceAddToCartAction?.status) {
      notifyAddToCart(referenceAddToCartAction.status);
    }
  }, [addToCartClicked, referenceAddToCartAction?.status, notifyAddToCart]);

  return (
    <SAdvertisementSpace
      onClick={() =>
        hasData(reference)
          ? history.push(`${ROUTER_PRODUCT}/${reference.referenceNumber}`)
          : notifyDetailError(referenceNumber ?? 'undefined')
      }
    >
      <LeftBannerSide title={banner.title} discount={banner.discount}>
        <Trans count={banner.remainingQty} i18nKey={'backoffice.promotion.banner.remaining_quantity'}>
          {'Only {{ count }} unit left.'}
        </Trans>
      </LeftBannerSide>
      <RightBannerSide>
        <PrivateComponent
          render={() => (
            <YellowButton
              disabled={!hasData(price)}
              size={'middle'}
              onClick={(e) => {
                e.stopPropagation();
                if (!hasData(reference)) {
                  notifyDetailError(referenceNumber ?? 'undefined');
                  return;
                }
                setAddToCartClicked(true);
                trackAppEvent(TRACKING_EVENT_ADD_PROMOTION_TO_CART);
                dispatch(addReferenceByRefNumberRequest({ reference: reference.referenceNumber }));
              }}
            >
              <Text type={'text_dim_bold'}>{t('cart.action.add_to_cart', 'Add to cart')}</Text>
            </YellowButton>
          )}
          requiredRights={[UserRole.COMMAND, UserRole.CONNECT_COMMANDE]}
        />
      </RightBannerSide>
    </SAdvertisementSpace>
  );
};
export default FlashQuantityLimitedBanner;
